<template>
  <div>
    <NavbarPrimary :links="navbarLinks" :showProfileIcon="true" />
  </div>
  <div class="contact-page-wrapper">
    <ContactUs
    title="Get in Touch"
    :showImage="true"
    submitButtonText="Send"
  />
  </div>

  <footerComp />
 
</template>

<script>
import NavbarPrimary from '@/components/NavbarPrimary.vue';
import footerComp from '../components/footerComp.vue';
import ContactUs from '@/components/ContactUs.vue';

export default {
  components: {
    NavbarPrimary,
    footerComp,
    ContactUs,
  },
  
  data() {
    return {
      navbarLinks: [
        { to: "/", text: 'Home' },
        
      ],
      
    };
  },
  
}
</script>

<style scoped>
.contact-page-wrapper :deep(.journey) {
  margin-top: 150px;
}
</style>
