<template>
    <div>
        <NavbarPrimary :bottomLinks="bottomNavbarLinks" :showProfileIcon="true" />
    </div>
    <div class="checkout-page">
        <h1>Checkout</h1>
        <div class="checkout-details">
            <div class="plan-details">
                <h2>{{ plan }} Plan</h2>
                <p class="price" v-if="localDiscountedPrice && localDiscountedPrice < localPrice">
                    <span class="original-price">{{ currencySymbol }}{{ localPrice }}</span>
                    {{ currencySymbol }}{{ localDiscountedPrice }} /month
                </p>
                <p class="price" v-else>
                    {{ currencySymbol }}{{ localPrice }} /month
                </p>
                <p>Thank you for choosing the {{ plan }} plan. Complete your purchase to start your subscription.</p>
            </div>
            <div class="checkout-form">
                <h3>Payment Details</h3>
                <button @click="initiatePayment" :disabled="isProcessing" class="custom-btn">
                    {{ isProcessing ? 'Processing...' : `Pay ${currencySymbol}${finalPrice}` }}
                </button>
            </div>
        </div>
        <div v-if="message" :class="['message', messageType]">
            {{ message }}
        </div>
    </div>

    <footerComp />
</template>

<script>
import NavbarPrimary from '@/components/NavbarPrimary.vue';
import footerComp from '../components/footerComp.vue';
import { mapState, mapActions } from 'vuex'

export default {
    components: {
        NavbarPrimary,
        footerComp,
    },


    props: ['plan', 'price', 'discountedPrice'],
    data() {
        return {
            bottomNavbarLinks: [
                { to: "/", text: 'HOME' },
                { to: '/PerformanceMarketing', text: "BACK" }

            ],
            razorpayKey: 'rzp_live_atNqMfuHYl9ldq',
            isProcessing: false,
            message: '',
            messageType: '',
            localPrice: this.price,
            localDiscountedPrice: this.discountedPrice,
        };
    },
    computed: {
        ...mapState(['country', 'currencySymbol', 'currency']),
        finalPrice() {
            return this.localDiscountedPrice && parseFloat(this.localDiscountedPrice) < parseFloat(this.localPrice)
                ? this.localDiscountedPrice
                : this.localPrice;
        }
    },
    methods: {
        ...mapActions(['detectCountry', 'setCountryPlans']),
        // async fetchRazorpayKey() {
        //     try {
        //         const response = await fetch('https://backend.broadwinglabs.com/get_razorpay_key');
        //         const data = await response.json();
        //         this.razorpayKey = data.razorpay_key_id;
        //     } catch (error) {
        //         console.error('Failed to fetch Razorpay key:', error);
        //         this.showMessage('Unable to initialize payment. Please try again later.', 'error');
        //     }
        // },

        updatePrice(newPrice) {
            this.$emit('update:price', newPrice);
        },
        async loadRazorpay() {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = 'https://checkout.razorpay.com/v1/checkout.js';
                script.onload = resolve;
                script.onerror = reject;
                document.head.appendChild(script);
            });
        },
        async initiatePayment() {
            this.isProcessing = true;
            this.message = '';
            try {
                await this.loadRazorpay();
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('No authentication token found');
                }

                const response = await fetch('https://backend.broadwinglabs.com/create_order', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        amount: this.finalPrice,
                        plan: this.plan,
                        country: this.country,
                        currency: this.currency,
                    })
                });

                if (!response.ok) {
                    console.log(response);
                    const errorData = await response.json();
                    throw new Error(errorData.msg || 'Payment initiation failed.');
                }

                const order = await response.json();

                const options = {
                    "key": this.razorpayKey,
                    "amount": order.amount,
                    "currency": "INR",
                    "order_id": order.id,
                    "handler": async (response) => {
                        try {
                            const verificationResponse = await fetch('https://backend.broadwinglabs.com/verify_payment', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}`
                                },
                                body: JSON.stringify({
                                    razorpay_order_id: response.razorpay_order_id,
                                    razorpay_payment_id: response.razorpay_payment_id,
                                    razorpay_signature: response.razorpay_signature
                                })
                            });

                            if (!verificationResponse.ok) {
                                throw new Error('Payment verification failed.');
                            }

                            const result = await verificationResponse.json();
                            console.log(result);

                            if (result.new_access_token) {
                                localStorage.setItem('token', result.new_access_token);
                            }

                            this.$store.dispatch('updateToken', result.new_access_token);

                            this.$store.dispatch('updateUserPlan', result.plan);

                            this.$router.push({
                                name: 'PaymentSummary',
                                query: {
                                    message: result.message,
                                    transactionId: result.transaction_id,
                                    paymentId: result.razorpay_payment_id,
                                    orderId: result.razorpay_order_id,
                                    amount: result.amount,
                                    currency: result.currency,
                                    createdAt: result.created_at,
                                    plan: result.plan
                                }
                            });
                        } catch (error) {
                            console.error('Payment verification error:', error);
                            this.showMessage('Payment verification failed. Please contact support.', 'error');
                        }
                    },
                    "modal": {
                        "ondismiss": async () => {
                            try {
                                await fetch('https://backend.broadwinglabs.com/payment_failed', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${token}`
                                    },
                                    body: JSON.stringify({
                                        razorpay_order_id: order.id
                                    })
                                });
                                this.showMessage('Payment cancelled. You can try again when you are ready.', 'info');
                            } catch (error) {
                                console.error('Failed to update payment status:', error);
                                this.showMessage('An error occurred. Please try again later.', 'error');
                            }
                        }
                    }
                };

                const rzp = new window.Razorpay(options);
                rzp.open();
            } catch (error) {
                console.error('Payment initiation failed:', error);
                this.showMessage('Unable to start payment process. Please try again later.', 'error');
            } finally {
                this.isProcessing = false;
            }
        },
        showMessage(msg, type) {
            this.message = msg;
            this.messageType = type;
            setTimeout(() => {
                this.message = '';
                this.messageType = '';
            }, 5000);
        },
        setPlanDetails() {
            const currentPlan = this.countryPlans.find(p => p.title === this.plan);
            if (currentPlan) {
                this.localPrice = currentPlan.price;
                this.localDiscountedPrice = currentPlan.discountedPrice;
            }
        },
        created() {
            this.localPrice = this.discountedPrice || this.price;
        },
    }
}
</script>


<style scoped>
.checkout-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    margin-top: 100px;
}

h1 {
    text-align: center;
    color: #333;
}

.checkout-details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.plan-details {
    flex: 1;
    margin-right: 20px;
}

.plan-details h2 {
    color: #333;
    margin-bottom: 10px;
}

.plan-details .price {
    font-size: 24px;
    font-weight: bold;
    color: #ff5722;
    margin-bottom: 20px;
}

.checkout-form {
    flex: 1;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: #555;
}

.form-group input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.btn {
    display: inline-block;
    background-color: #ff5722;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn:hover {
    background-color: #e64a19;
}

.custom-btn:hover {
    background-color: #17410e;
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

}

.custom-btn {
    margin-right: 10px;
    color: white !important;
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 4px;
    transition: all 0.3s ease;
    background-color: #2F847C;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.custom-btn:last-child {
    margin-right: 10px;
}

.original-price {
    text-decoration: line-through;
    color: #888;
    margin-right: 10px;
}

.message {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}

.error {
    background-color: #ffebee;
    color: #c62828;
    border: 1px solid #ef9a9a;
}

.success {
    background-color: #e8f5e9;
    color: #2e7d32;
    border: 1px solid #a5d6a7;
}

.info {
    background-color: #e3f2fd;
    color: #1565c0;
    border: 1px solid #90caf9;
}

button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}
</style>
