<template>
    <div class="home-primary">
      <div class="wave-container">
        <!-- <img src="Landingpage-01.png" alt="WAVE" width="1500px" height="500px"> -->
        <NavbarPrimary :topLinks="topNavbarLinks" :showProfileIcon="false"/>
      </div>
      <div class="home">
        <div class="welcome-text">
          <h1>Welcome</h1>
          <h3 class="to-text">to</h3> 
          <img class="brand-logo" src="Broadwings Logo Final 2-01.png" alt="BL" width="1000px" height="150px">
          <p>Transforming your E-Commerce vision into reality</p>
        </div>
        <p>Discover the power of strategic growth with Broadwing Labs, where every decision is backed by data, and every strategy is designed for business growth.</p>
      </div>
    </div>
  </template>
  
  <script>
  import NavbarPrimary from '../components/NavbarPrimary.vue';
  
  export default {
  components: {
    NavbarPrimary,
  },
  data() {
    return {
      topNavbarLinks: [
        { to: '/ConsultingCommerce', text: 'CONSULTING' },
        { to: '/PerformanceMarketing', text: 'MARKETING' },
        { to: '/career', text: 'CAREER' },
      ],
    };
    
  },

};
  </script>
  
  <style scoped>
  /* .home-primary {
    position: relative;
  }
  
  .wave-container {
    position: relative;
    overflow: hidden;
  } */
  
  .home {
    text-align: center;
    /* padding: 1px 5px; */
    position: absolute; 
    bottom: 10rem; 
    left: 50%; 
    transform: translateX(-50%); 
    width: 100%; 
  }
  
  /* .welcome-text {
    margin-top: 100px;
  } */
  
  .welcome-text h1 {
    margin: 0;
    font-size: 2em;
    color: #17252A;
  }
  
  .welcome-text h3 {
    /* margin: 10px 0; */
    font-size: 2em; 
  }
  
  .brand-logo {
    animation: scaleAnimation 2s infinite alternate; 
  }
  .home p{
    font-size: 1.0em;
  }
  
  @keyframes scaleAnimation {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.05);
    }
  }
  
  .welcome-text p {
    font-size: 1.5em;
    color: #007B7F;
    margin-bottom: 10px;
  }
  </style>