<template>
    <v-container fluid class="pa-6 admin-container">
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6">
                <h2>Manage Amazon Profiles</h2>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field v-model="profileId" :rules="profileIdRules" label="Profile ID"
                        required></v-text-field>

                    <v-text-field v-model="name" :rules="nameRules" label="Name" required></v-text-field>

                    <v-text-field v-model="emailId" :rules="emailIdRules" label="Email ID" required></v-text-field>

                    <v-select v-model="region" :items="regions" label="Region" required></v-select>

                    <v-select v-model="country" :items="countries" label="Country" item-title="text" item-value="value" required></v-select>

                    <v-btn :disabled="!valid" color="primary" @click="addProfile">
                        Add Profile
                    </v-btn>
                </v-form>
            </v-col>
        </v-row>

        <v-row class="mt-8" align="center" justify="center">
            <v-col cols="12">
                <v-card>
                    <h2>Amazon Profiles</h2>
                    <v-card-title>
                        
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                            hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="profiles" :search="search" :loading="loading"
                        class="elevation-1">
                        <template v-slot:item="{ item }">
                            <tr class="profile-row" @click="highlightRow(item)">
                                <td>{{ item.ProfileId }}</td>
                                <td>{{ item.Name }}</td>
                                <td>{{ item.EmailId }}</td>
                                <td>{{ item.region }}</td>
                                <td>{{ item.country }}</td>
                                <td><v-btn color="error" dark icon size="x-small" @click.stop="deleteItem(item.ProfileId)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn></td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
        {{ snackbar.message }}
        <v-btn color="white" text @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
</template>

<script>
export default {
    data() {
        return {
            valid: false,
            profileId: '',
            name: '',
            emailId: '',
            profileIdRules: [
                v => !!v || 'Profile ID is required',
                v => (v && v.length <= 150) || 'Profile ID must be less than 250 characters',
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 100) || 'Name must be less than 100 characters',
            ],
            emailIdRules: [
                v => !!v || 'Email ID is required',
                v => /.+@.+\..+/.test(v) || 'Email must be valid',
            ],
            snackbar: {
                show: false,
                message: '',
                color: '',
                timeout: 4000
            },
            search: '',
            headers: [
                { title: 'Profile ID', key: 'ProfileId' },
                { title: 'Name', key: 'Name' },
                { title: 'Email ID', key: 'EmailId' },
                { title: 'Region', key: 'region' },
                { title: 'Country', key: 'country' }
            ],
            countries: [
                { text: 'Australia (AU)', value: 'AU' },
                { text: 'Belgium (BE)', value: 'BE' },
                { text: 'Brazil (BR)', value: 'BR' },
                { text: 'Canada (CA)', value: 'CA' },
                { text: 'Egypt (EG)', value: 'EG' },
                { text: 'France (FR)', value: 'FR' },
                { text: 'Germany (DE)', value: 'DE' },
                { text: 'India (IN)', value: 'IN' },
                { text: 'Italy (IT)', value: 'IT' },
                { text: 'Japan (JP)', value: 'JP' },
                { text: 'Mexico (MX)', value: 'MX' },
                { text: 'Netherlands (NL)', value: 'NL' },
                { text: 'Poland (PL)', value: 'PL' },
                { text: 'Saudi Arabia (SA)', value: 'SA' },
                { text: 'Singapore (SG)', value: 'SG' },
                { text: 'Spain (ES)', value: 'ES' },
                { text: 'Sweden (SE)', value: 'SE' },
                { text: 'Turkey (TR)', value: 'TR' },
                { text: 'United Arab Emirates (AE)', value: 'AE' },
                { text: 'United Kingdom (GB)', value: 'GB' },
                { text: 'United States (US)', value: 'US' },
            ],
            profiles: [],
            loading: false,
            highlightedProfileId: null,
            region: '',
            country: '',
            regions: ['NA', 'EU', 'FE'],
            regionRules: [
            v => !!v || 'Region is required'
        ],
        };
    },
    methods: {
        async addProfile() {
            if (this.$refs.form.validate()) {
                const profileData = {
                    profileId: this.profileId,
                    name: this.name,
                    emailId: this.emailId,
                    region: this.region,
                    country: this.country,
                };

                try {
                    const response = await fetch('https://backend.broadwinglabs.com/add_profile', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(profileData),
                    });

                    if (response.status === 201) {
                        this.$refs.form.reset();
                        this.showSnackbar("Profile Added Successfully", 'success');
                        this.fetchProfiles();
                    } else {
                        this.showSnackbar("Unable to Add Profile", 'error');
                    }
                } catch (error) {
                    // console.error('There was an error adding the profile:', error);
                    this.showSnackbar("Error adding profile", 'error');
                }
            }
        },
        showSnackbar(message, type) {
            this.snackbar.message = message;
            this.snackbar.color = type === 'success' ? 'green' : 'red';
            this.snackbar.show = true;
        },
        async fetchProfiles() {
            this.loading = true;
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('https://backend.broadwinglabs.com/get_profiles', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                this.profiles = data;
            } catch (error) {
                // console.error('Error fetching profiles:', error);
                this.showSnackbar("Error fetching profiles", 'error');
            } finally {
                this.loading = false;
            }
        },
        async deleteItem(profileId) {
            if (confirm('Are you sure you want to delete this profile?')) {
                try {
                    const token = localStorage.getItem('token');
                    const response = await fetch(`https://backend.broadwinglabs.com/delete_profile/${profileId}`, {
                        method: 'DELETE',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    if (response.ok) {
                        this.showSnackbar("Profile deleted successfully", 'success');
                        this.fetchProfiles(); 
                    } else {
                        this.showSnackbar("Failed to delete profile", 'error');
                    }
                } catch (error) {
                    // console.error('Error deleting profile:', error);
                    this.showSnackbar("Error deleting profile", 'error');
                }
            }
        },
        highlightRow(item) {
            this.highlightedProfileId = item.ProfileId;
        },
    },
    mounted() {
        this.fetchProfiles();
    },
};
</script>

<style scoped>
h2 {
    text-align: center;
    margin-bottom: 20px;
    color: rgb(27, 18, 68);
}

.profile-row {
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.profile-row:hover {
    background-color: #f5f5f5;
}

.highlighted {
    background-color: #f8bbbb;
}

.v-data-table {
    transition: opacity 0.3s ease;
}
.admin-container{
    background-color: white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); 
    border-radius: 8px; 
    border: 2px solid rgba(0, 0, 0, 0.05);
    background: linear-gradient(to bottom, rgb(234, 241, 239), #dff5f3);
    margin-top: 125px;
}
</style>