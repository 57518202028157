<template>
    <div class="wrapper">
  <h1>coming soon<span class="dot">.</span></h1>
  <p>Carrer/Jobs.</p>
  <div class="icons">
    <p>Let's go <a  @click.prevent="goToHome">Home</a> and try from there.</p>
    <!-- <a href=""><i class="fa fa-twitter"></i></a>
    <a href=""><i class="fa fa-youtube-play"></i></a>
    <a href=""><i class="fa fa-paper-plane"></i></a> -->
  </div>
 </div>
</template>
<script>
export default{
  methods: {
    goToHome(){
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>
body {
  background: #00091B;
  color: #fff;
}


@keyframes fadeIn {
  from {top: 20%; opacity: 0;}
  to {top: 100; opacity: 1;}
  
}

@-webkit-keyframes fadeIn {
  from {top: 20%; opacity: 0;}
  to {top: 100; opacity: 1;}
  
}

.wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  animation: fadeIn 1000ms ease;
  -webkit-animation: fadeIn 1000ms ease;
  
}

h1 {
  font-size: 50px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0;
  line-height: 1;
  font-weight: 700;
}

.dot {
  color: #4FEBFE;
}

p {
  text-align: center;
  margin: 18px;
  font-family: 'Muli', sans-serif;
  font-weight: normal;
  
}

a {
  cursor: pointer;
  text-decoration: none;
  color: rgb(212, 28, 28);
}
</style>