<template>
    <nav class="navbar">
        <div class="navbar-top">
            <router-link to="/" class="logo-link">
                <img src="Logo_11.png" alt="BL">
            </router-link>
            <div class="navbar-top-right">
                <ul class="navbar-top-links">
                    <li v-for="(link, index) in topLinks" :key="`top-${index}`">
                        <router-link v-if="link.to" :to="link.to">{{ link.text }}</router-link>
                        <a v-else @click.prevent="handleClick(link)" href="#">{{ link.text }}</a>
                    </li>
                </ul>
                <div class="profile-icon-wrapper">
                    <i class="fas fa-user-circle profile-icon" @click="toggleDropdown"></i>
                </div>
            </div>
        </div>
        <div v-if="showDropdown" class="dropdown-overlay" @click="closeDropdown">
            <div class="dropdown-menu" @click.stop>
                <a v-if="isLoggedIn" @click="goToUserProfile" class="dropdown-item">Profile</a>
                <a v-if="isLoggedOut" @click.prevent="goToLoginSignup" class="dropdown-item">Login/Signup</a>
                <a v-if="isLoggedIn" @click.prevent="logout" class="dropdown-item">Logout</a>
            </div>
        </div>


        <div class="navbar-content">
            <div class="line-with-arrows"></div>
        </div>
        <ul class="navbar-bottom-links">
            <li v-for="(link, index) in filteredBottomLinks" :key="`bottom-${index}`">
                <router-link v-if="link.to" :to="link.to">{{ link.text }}</router-link>
                <a v-else @click.prevent="handleClick(link)" href="#">{{ link.text }}</a>
            </li>
        </ul>
    </nav>
</template>

<script>
import { jwtDecode } from 'jwt-decode';
export default {
    data() {
        return {
            isLoggedIn: localStorage.getItem("token") != null,
            isLoggedOut: localStorage.getItem("token") == null,
            username: localStorage.getItem("username"),
            isAdmin: false,
            userPlan: 'NO_PLAN',
            showDropdown: false,
            isHomePage: this.$route.name === 'home',

        };
    },
    props: {
        topLinks: {
            type: Array,
            required: true,
        },
        bottomLinks: {
            type: Array,
            required: true,
        },
        showProfileIcon: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
    filteredBottomLinks() {
      return this.bottomLinks.filter(link => {
        if (link.text === 'HOME') return true;
        if (link.text === 'ADMIN') return this.isAdmin;
        if (link.text === 'AMS OPTIMIZATION TOOL') return this.isAdmin || this.userPlan === 'PREMIUM';
        if (link.text === 'MARKETING OPTIMIZATION TOOL') return this.isAdmin || this.userPlan === 'BASIC' || this.userPlan === 'PREMIUM';
        return false;
      });
    },
    },
    created() {
        this.checkAdmin();
        this.checkUserStatus();

    },
    methods: {
        closeDropdown() {
            this.showDropdown = false;
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.showDropdown = false;
            } else {
                this.showDropdown = true;
            }
        },
        goToLoginSignup() {
            this.$router.push('/LoginSignup');
        },
        handleClick(link) {
            if (link.handler) {
                this.$emit(link.handler, link.target);
            }
        },
        goToHome() {
            this.$router.push('/');
        },
        goToAdmin() {
            this.$router.push('/AdminView');
        },
        getUsername() {
            return this.username;
        },
        gotoAmazon() {
            return this.$router.push('/AmazonView');
        },
        gotoUpload() {
            return this.$router.push('/UploadView');
        },
        goToUserProfile(){
            return this.$router.push('/UserProfile');
        },

        checkAdmin() {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    this.isAdmin = decodedToken.sub.is_admin;
                } catch (error) {
                    console.error('Error decoding token:', error);
                }
            }
        },
        checkUserStatus() {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          this.isAdmin = decodedToken.sub.is_admin;
          this.userPlan = decodedToken.sub.plan;
        } catch (error) {
          console.error('Error decoding token:', error);
        }
      }
    },
        logout() {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            this.isLoggedIn = false;
            this.userPlan = 'NO_PLAN';

            if (this.$router.name !== "home") {
                this.$router.push({ name: "home" });
                this.$router.go();
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }
};
</script>

<style scoped>
.profile-icon-wrapper {
    margin-left: 10px;
}

.profile-icon {
    font-size: 2.5rem;
    color: #17252A;
    cursor: pointer;
}


.navbar {
    background: none;
    padding: 20px 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.navbar-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
}

.navbar-top-right {
    display: flex;
    align-items: center;
}

.logo-link {
    display: flex;
    align-items: center;
}

.navbar-top-links {
    list-style: none;
    display: flex;
    gap: 20px;
    justify-content: right;
    width: 100%;
    padding: 0;
    margin-top: 10px;
}

.navbar-bottom-links {
    list-style: none;
    display: flex;
    gap: 20px;
    justify-content: center;
    width: 100%;
    padding: 0;
    margin-top: 10px;
}
a{
    cursor: pointer;
    text-decoration: none;
    color: white;
}
.navbar-content {
    padding: 0 50px;
    margin-top: 10px;
}

.dropdown-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    z-index: 9999;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 64px;
    /* Adjust this value to match your navbar height */
}

.dropdown-menu {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    max-height: 80vh;
    overflow-y: auto;
    margin-right: 20px;
}

.dropdown-item {
    display: block;
    padding: 12px 20px;
    color: #17252A;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #c5cae9;
    ;
}

.line-with-arrows {
    position: relative;
    width: 100%;
    height: 2px;
    background-color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.line-with-arrows::before,
.line-with-arrows::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
}

.line-with-arrows::before {
    border-width: 5px 10px 5px 0;
    border-color: transparent #000 transparent transparent;
    margin-left: -10px;
}

.line-with-arrows::after {
    border-width: 5px 0 5px 10px;
    border-color: transparent transparent transparent #000;
    margin-right: -10px;
}

.navbar-top-links a,
.navbar-bottom-links a {
    color: #17252A;
    text-decoration: none;
    font-size: 1.0rem;
    font-weight: bold;
}
</style>