<template>
  <v-app>
    <NavbarMarketing :topLinks="topNavbarLinks" :bottomLinks="bottomNavbarLinks" :showProfileIcon="false" />
    <v-main>
      <v-container>
        <v-row align="center" fluid justify="center" v-if="isAdmin">
          <v-col cols="12" sm="8" md="10">
            <AdminComp />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavbarMarketing from '@/components/NavbarMarketing.vue';
import AdminComp from '../components/AdminComp.vue';
import { jwtDecode } from 'jwt-decode';


export default {
  data() {
    return {
      isLoggedIn: localStorage.getItem("token") != null,
    isLoggedOut: localStorage.getItem("token") == null,
    username: localStorage.getItem("username"),
    isAdmin: false,
    topNavbarLinks: [
      { to: "/", text: 'HOME', show: true },
      { to: '/ConsultingCommerce', text: 'CONSULTING' },
      { to: '/PerformanceMarketing', text: 'MARKETING' },
      { to: '/career', text: 'CAREER' },
    ],
    };
  },
  components: {
    NavbarMarketing,
    AdminComp,
  },
  created() {
    this.checkAdmin();
  },
  computed: {
  bottomNavbarLinks() {
    return [
    
      { to: "/AmazonView", text: 'AMS OPTIMIZATION TOOL', show: this.isLoggedIn && this.isAdmin },
      { to: "/UploadView", text: 'MARKETING OPTIMIZATION TOOL', show: this.isLoggedIn && this.isAdmin },
      { to: "/AdminView", text: 'ADMIN', show: this.isLoggedIn && this.isAdmin },
    ];
  },
},
  methods: {
    getUsername() {
      return this.username;
    },
    checkAdmin() {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);

          this.isAdmin = decodedToken.sub.is_admin;
        } catch (error) {
          console.error('Error decoding token:', error);
        }
      }
    },
  },
};
</script>

<style scoped>
.welcome-message {
  font-size: 1.25rem;
  font-weight: 500;
  color: #4caf50; 
  background-color: #e8f5e9; 
  border: 1px solid #c8e6c9; 
  border-radius: 8px; 
}
</style>
