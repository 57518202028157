// tokenService.js
let tokenExpirationTimer = null;

export function startTokenExpirationTimer(logoutCallback, expirationTime) {
  if (tokenExpirationTimer) {
    clearTimeout(tokenExpirationTimer);
  }

  const currentTime = new Date().getTime();
  const timeUntilExpiration = expirationTime - currentTime;

  if (timeUntilExpiration > 0) {
    tokenExpirationTimer = setTimeout(() => {
      logoutCallback();
    }, timeUntilExpiration);
  } else {
    logoutCallback();
  }
}

export function clearTokenExpirationTimer() {
  clearTimeout(tokenExpirationTimer);
}