<template>
    <footer class="footer">
        <div class="footer-content">
            <div class="footer-left">
                <p>Email Us: <a href="mailto:adtech@broadwinglabs.com">adtech@broadwinglabs.com</a></p>
                <p><a href="#">Privacy Policy</a> | <a href="#">Terms & Conditions</a></p>
            </div>
            <div class="footer-center">
                <p>&copy; Broadwinglabs.com</p>
            </div>
            <div class="footer-right">
                <p>Follow us</p>
                <div class="social-icons">
                    <a href="#" class="icon-facebook"><i class="fab fa-facebook"></i></a>
                    <a href="#" class="icon-instagram"><i class="fab fa-instagram"></i></a>
                    <a href="#" class="icon-linkedin"><i class="fab fa-linkedin-in"></i></a>
                </div>
            </div>
        </div>
    </footer>
</template>

<style scoped>

/* footer css */
.footer {
    background-color: #f8f8f8;
    padding: 20px 0;
    margin-top: 10px;
    border-top: 1px solid #e0e0e0;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    padding: 20px 20px 0;
    border-top: 1px solid #1a1313;
}

.footer-left,
.footer-center,
.footer-right {
    flex: 1;
}

.footer-left {
    text-align: left;
}

.footer-center {
    text-align: center;
}

.footer-right {
    text-align: right;
}

.footer p,
.footer a {
    margin: 5px 0;
    color: #333;
    text-decoration: none;
}
a{
    cursor: pointer;
    text-decoration: none;
    color: white;
}

.social-icons {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}

.social-icons a {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eea2a2;
    color: #fff;
    border-radius: 50%;
    text-decoration: none;
    transition: background-color 0.3s;
}

.social-icons a:hover {
    background-color: #555;
}

.social-icons i {
    font-size: 16px;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        text-align: center;
    }

    .footer-left,
    .footer-center,
    .footer-right {
        margin-bottom: 20px;
    }

    .social-icons {
        justify-content: center;
    }
}
</style>