<template>
    <div>
        <NavbarPrimary :topLinks="topNavbarLinks" :bottomLinks="bottomNavbarLinks" :showProfileIcon="true" @scrollTo="scrollTo" />
    </div>
    <div id="content" class="content">
        <div class="text-section">
            <h2>What We Do</h2>
            <p>
                At Broadwing Labs, we provide a streamlined advertising strategy that integrates all relevant
                campaign types, targeting, and data insights to supercharge performance.
            </p>
        </div>
        <div class="image-section">
            <img src="whatwedo.png" alt="Performance Marketing" />
        </div>
    </div>
    <div class="purpose">
        <div class="text-section-purpose">
            <h2>Our Purpose</h2>
            <p>At Broadwing Labs, we aim to simplyfy e-commerce advertising with precision, innovation, and
                relentless optimization, driving unmatched growth and efficiency for our clients.
            </p>
        </div>
        <div class="purpose-image-section">
            <img src="purpose.png" alt="Performance Marketing" />
        </div>
    </div>
    <div class="engine">
        <div class="engine-text-section">
            <h2>Differential Optimization (DO) Engine</h2>
            <p>Experience remarkable sales growth with
                Broadwing Labs' cutting-edge
                Differential Optimization (DO) engine.</p>
            <p>Our innovative technology ensures every
                dollar of your budget works harder,
                delivering continuous and exceptional
                campaign performance.</p>
        </div>
        <div class="engine-image-section">
            <img src="DO.png" alt="DO Engine Img" width="450px">
        </div>
    </div>
    <div class="journey">
        <div class="journey-content">
            <div class="journey-text-section">
                <h2>Your Journey to E-commerce Growth</h2>
                <p>Overcoming Setbacks to Drive Results</p>
            </div>
            <div class="journey-image-section">
                <img src="Journey.png" alt="Journey image" width="1000px">
            </div>
        </div>
    </div>

    <!-- -------------------------------- -->
    <PaymentCards id="paymentcards"/>
    <!-- ----------------------------------------------------------- -->
    
    <div id="platform" class="platform">
        <div class="platform-content">
            <div class="platform-text-section">
                <h2>OUR PLATFORMS</h2>
            </div>
            <div class="platform-image-section">
                <img src="Platform.png" alt="Platform image">
            </div>
        </div>
    </div>
    <div id="partners" class="partners">
        <div class="partners-content">
            <div class="platform-text-section">
                <h2>OUR PARTNERS</h2>
            </div>
            <div class="partners-image-section">
                <img src="Partners.png" alt="partners image" width="1000px">
            </div>
        </div>
    </div>
    <footerComp/>

</template>

<script>
import NavbarPrimary from '@/components/NavbarPrimary.vue';
import footerComp from '../components/footerComp.vue';
import PaymentCards from '@/components/PaymentCards.vue';
export default {
    components: {
        NavbarPrimary,
        footerComp,
        PaymentCards,
    },
    data() {
    return {
      isLoggedIn: localStorage.getItem("token") != null,
      
      topNavbarLinks: [
      { to: "/", text: 'HOME' },
              
      { to: '/ConsultingCommerce', text: 'CONSULTING' },
        { to: '/PerformanceMarketing', text: 'MARKETING' },
        { to: '/career', text: 'CAREER' },
      
      ],
      bottomNavbarLinks: [
  

        
        { handler: 'scrollTo', target: 'content', text: 'ABOUT' },
        { handler: 'scrollTo', target: 'paymentcards', text: 'SUBSCRIPTION' },
        { handler: 'scrollTo', target: 'platform', text: 'PLATFORMS' },
        { handler: 'scrollTo', target: 'partners', text: 'PARTNERS' },
        
      ],
    };
  },
  methods: {
  scrollTo(target) {
    const element = document.getElementById(target);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
},

}
</script>

<style scoped>
/*content css */
.content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.text-section {
    max-width: 50%;
    padding: 20px;
}

.text-section h2 {
    color: #2F847C;
    font-size: 2rem;
    margin-bottom: 20px;
}

.text-section p {
    font-size: 1.2rem;
    color: #17252A;
}

.image-section {
    max-width: 30%;
    padding: 20px;
}

.image-section img {
    width: 100%;
    height: auto;
}

/* purpose css */
.purpose {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.text-section-purpose {
    max-width: 50%;
    padding: 20px;
    order: 2;
}

.text-section-purpose h2 {
    color: #2F847C;
    font-size: 2rem;
    margin-bottom: 20px;
}

.text-section-purpose p {
    font-size: 1.2rem;
    color: #17252A;
}

.purpose-image-section {
    max-width: 30%;
    padding: 20px;
    order: 1;
}

.purpose-image-section img {
    width: 100%;
    height: auto;
}

/* engine css */
.engine {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.engine-text-section {
    max-width: 50%;
    padding: 70px;

}

.engine-text-section h2 {
    color: #2F847C;
    font-size: 2rem;
    margin-bottom: 20px;
}

.engine-text-section p {
    font-size: 1.2rem;
    color: #17252A;
    margin-bottom: 20px;
}

.engine-image-section {
    max-width: 40%;
    padding: 30px;
}

.engine-image-section {
    width: 100%;
    height: auto;
}

/*Journey css */
.journey {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
}

.journey-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    width: 100%;
}

.journey-text-section {
    margin-bottom: 20px;
}

.journey-text-section h2 {
    color: #2F847C;
    font-size: 2rem;
    margin-bottom: 10px;
}

.journey-text-section p {
    font-size: 1.2rem;
    color: #17252A;
}

.journey-image-section img {
    max-width: 100%;
    height: auto;
}

/* platform css */
.platform {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
}

.platform-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    width: 100%;
}

.platform-text-section h2 {
    color: #2F847C;
    font-size: 2rem;
    /* margin-top: 10px; */
}

.platform-image-section img {
    max-width: 100%;
    height: auto;
}

/* partners css */
.partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
}

.partners-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    width: 100%;
}

.partners-text-section h2 {
    color: #2F847C;
    font-size: 2rem;
    margin-bottom: 10px;
}

.partners-image-section img {
    max-width: 100%;
    height: auto;
}



</style>