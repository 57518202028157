
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginSignup from '../components/LoginSignup.vue'
import AmzDashboard from '../components/AmzDashboard.vue'
import AdminComp from '../components/AdminComp.vue'
import CampaignDetails from '../components/CampaignDetails.vue'
import AmazonView from '../views/AmazonView.vue'
import UploadView from '../views/UploadView.vue'
import AdminView from '../views/AdminView.vue'
import UploadComp from '../components/UploadComp.vue'
import NavbarPrimary from '../components/NavbarPrimary.vue'
import HomePrimary from '../views/HomePrimary.vue'
import PerformanceMarketing from '../views/PerformanceMarketing.vue'
import ConsultingCommerce from '../views/ConsultingCommerce.vue'
import footerComp from '../components/footerComp.vue'
import CheckoutPage from '../views/CheckoutPage.vue'
import ContactPage from '../views/ContactPage.vue'
import ContactUs from '@/components/ContactUs.vue'
import PaymentSummary from '../views/PaymentSummary.vue'
import EliteContact from '../views/EliteContact.vue'
import PaymentCards from '@/components/PaymentCards.vue'
import UserProfile from '@/views/UserProfile.vue'
import NavbarMarketing from '@/components/NavbarMarketing.vue'
import career from '@/views/CareerJobs.vue'
import PageNotFound from '@/views/PageNotFound.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/HomePrimary',
    name: 'HomePrimary',
    component: HomePrimary
  },
  {
    path: '/career',
    name: 'career',
    component: career
  },

  {
    path: '/PaymentCards',
    name: 'PaymentCards',
    component: PaymentCards
  },
  {
    path: '/UserProfile',
    name: 'UserProfile',
    component: UserProfile
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/NavbarMarketing',
    name: 'NavbarMarketing',
    component: NavbarMarketing
  },
  {
    path: '/ContactPage',
    name: 'ContactPage',
    component: ContactPage
  },
  {
    path: '/EliteContact',
    name: 'EliteContact',
    component: EliteContact
  },
  {
    path: '/CheckoutPage',
    name: 'CheckoutPage',
    component: CheckoutPage,
    props: route => ({ plan: route.query.plan, price: route.query.price })
  },
  {
    path: '/payment-summary',
    name: 'PaymentSummary',
    component: PaymentSummary,
    props: route => ({ ...route.query }),
},
 
  {
    path: '/footerComp',
    name: 'footerComp',
    component: footerComp
  },
  {
    path: '/ConsultingCommerce',
    name: 'ConsultingCommerce',
    component: ConsultingCommerce
  },
  {
    path: '/PerformanceMarketing',
    name: 'PerformanceMarketing',
    component: PerformanceMarketing
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/AdminComp',
    name: 'AdminComp',
    component: AdminComp
  },
  {
    path: '/UploadComp',
    name: 'UploadComp',
    component: UploadComp
  },
  {
    path: '/AdminView',
    name: 'AdminView',
    component: AdminView
  },
  {
    path: '/AmazonView',
    name: 'AmazonView',
    component: AmazonView
  },
  {
    path: '/UploadView',
    name: 'UploadView',
    component: UploadView
  },
  {
    path: '/CampaignDetails',
    name: 'CampaignDetails',
    component: CampaignDetails
  },

  {
    path: '/NavbarPrimary',
    component: NavbarPrimary,
    name: 'NavbarPrimary'
  },
  {
    path: '/LoginSignup',
    name: "LoginSignup",
    component: LoginSignup
  },
  {
  path: '/AmzDashboard',
  component: AmzDashboard
  },
  {
    path: '/:catchAll(.*)',
    name: "PageNotFound",
    component: PageNotFound
    }
];

const router = createRouter({
  history: createWebHistory(),
  routes,

})

export default router
