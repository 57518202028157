<template>
  <v-app>
    <NavbarMarketing :topLinks="topNavbarLinks" :bottomLinks="bottomNavbarLinks" :showProfileIcon="false" />

    <v-main>
      <v-container>
        <UploadComp />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavbarMarketing from '@/components/NavbarMarketing.vue';
import { jwtDecode } from 'jwt-decode';
import UploadComp from '../components/UploadComp.vue';

export default {
  data() {
    return {
      file: null,
      isLoggedIn: localStorage.getItem("token") != null,
      isLoggedOut: localStorage.getItem("token") == null,
      username: localStorage.getItem("username"),
      isAdmin: false,

      topNavbarLinks: [
      { to: "/", text: 'HOME', show: true },
        { to: '/ConsultingCommerce', text: 'CONSULTING' },
        { to: '/PerformanceMarketing', text: 'MARKETING' },
        { to: '/career', text: 'CAREER' },
      ],

    }
  },
  created() {
    this.checkAdmin();

  },
  computed: {
    bottomNavbarLinks() {
      return [
        
        { to: "/AmazonView", text: 'AMS OPTIMIZATION TOOL', show: this.isLoggedIn && this.isAdmin },
        { to: "/UploadView", text: 'MARKETING OPTIMIZATION TOOL', show: this.isLoggedIn && this.isAdmin },
        { to: "/AdminView", text: 'ADMIN', show: this.isLoggedIn && this.isAdmin },
      ];
    },
  },
  components: {
    NavbarMarketing,
    UploadComp
  },
  methods: {
    checkAdmin() {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          this.isAdmin = decodedToken.sub.is_admin;
        } catch (error) {
          console.error('Error decoding token:', error);
        }
      }
    },
  }

};
</script>

<style scoped></style>
