<template>
    <div>
      <NavbarPrimary :bottomLinks="bottomNavbarLinks" :showProfileIcon="true" />
    </div>
    <div class="contact-page-wrapper">
      <ContactUs
    title="Get in Touch"
    :showImage="true"
    :fields="[
      { name: 'name', type: 'text', placeholder: 'Your Name', required: true },
      { name: 'CompanyName', type: 'text', placeholder: 'Company Name', required: false },
      { name: 'CountryMarket', type: 'text', placeholder: 'Country/Market', required: false },
      { name: 'AnnualTurnover', type: 'text', placeholder: 'AnnualTurnover', required: false },
      { name: 'MarketingBudget', type: 'text', placeholder: 'Marketing Budget', required: false },
      { name: 'email', type: 'email', placeholder: 'Your Email', required: true },
      { name: 'contactNumber', type: 'tel', placeholder: 'Contact Number', required: false },
      { name: 'subject', type: 'text', placeholder: 'Subject', required: true },
      { name: 'message', type: 'textarea', placeholder: 'Your Message', required: true }
    ]"
    submitButtonText="Send"
  />
    </div>
  
    <footerComp />
  
  </template>
  
  <script>
  import NavbarPrimary from '@/components/NavbarPrimary.vue';
  import footerComp from '../components/footerComp.vue';
  import ContactUs from '@/components/ContactUs.vue';
  export default {
    components: {
      NavbarPrimary,
      footerComp,
      ContactUs
    },
    data() {
      return {
        bottomNavbarLinks: [
          { to: "/", text: 'HOME' },
          { to: "/PerformanceMarketing", text: 'BACK' },
          // { to: '/ContactUs', text: 'Contact Us' },
        ],
       
      };
    },
    
  }
  
  </script>
 <style scoped>
 .contact-page-wrapper :deep(.journey) {
   margin-top: 150px;
 }
 </style>