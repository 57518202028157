import { createStore } from 'vuex'
import axios from 'axios'

const currencySymbolsMap = {
    IN: '₹',
    US: '$',
    GB: '£',
    NL: '€',
    FR: '€',
    SA: 'SAR',
    AE: 'AED'
};

const defaultCurrencySymbol = '$';

export default createStore({
  state: {
    country: '',
    currencySymbol: '',
    countryPlans: [],
  },
  getters: {
    getCountry: state => state.country,
    getCurrencySymbol: state => state.currencySymbol,
    getCountryPlans: state => state.countryPlans,
  },
  mutations: {
    setCountry(state, country) {
      state.country = country;
    },
    setCurrencySymbol(state, symbol) {
      state.currencySymbol = symbol;
    },
    setCountryPlans(state, plans) {
      state.countryPlans = plans;
    },
    setCurrency(state, currency) {
      state.currency = currency;
    },
  },
  actions: {
    async detectCountry({ commit }) {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        console.log(response);
        const country = response.data.country_code;
        const currency = response.data.currency;
        commit('setCountry', country);
        commit('setCurrency', currency);
        return { country, currency };
      } catch (error) {
        console.error('Error detecting country:', error);
        commit('setCountry', 'US');
        commit('setCurrency', 'USD');
        return { country: 'US', currency: 'USD' };
      }
    },
    setCountryPlans({ commit, state }) {
      const plans = {
        IN: [
          {
              title: 'BASIC',
            //   1999
              price: 1999,
              discountedPrice: null,
              description: 'Free Trial For 7 days',
              items: [
                  { name: 'Access to DO Engine', included: true },
                  { name: 'Onboarding', included: false },
                  { name: 'Automated Campaign Management', included: false },
                  { name: 'Dedicated Consultant', included: false },
                  { name: 'Sales Consulting', included: false },
                  { name: 'Pricing Consulting', included: false },
                  { name: 'Marketing Consulting', included: false },
                  { name: 'Performance Reviews', included: false },

              ]
          },
          {
              title: 'PREMIUM',
              price: 9999,
            //   4999
              discountedPrice: 4999,
              description: 'Free Trial For 7 days',
              items: [
                { name: 'Access to DO Engine', included: true },
                { name: 'Onboarding', included: true },
                { name: 'Automated Campaign Management', included: true },
                { name: 'Dedicated Consultant', included: false },
                { name: 'Sales Consulting', included: false },
                { name: 'Pricing Consulting', included: false },
                { name: 'Marketing Consulting', included: false },
                { name: 'Performance Reviews', included: false },

              ]
          },
      ],
      US: [
          {
              title: 'BASIC',
              price: 99,
              discountedPrice: null,
              description: 'Free Trial For 7 days',
              items: [
                { name: 'Access to DO Engine', included: true },
                { name: 'Onboarding', included: false },
                { name: 'Automated Campaign Management', included: false },
                { name: 'Dedicated Consultant', included: false },
                { name: 'Sales Consulting', included: false },
                { name: 'Pricing Consulting', included: false },
                { name: 'Marketing Consulting', included: false },
                { name: 'Performance Reviews', included: false },
              ]
          },
          {
              title: 'PREMIUM',
              price: 499,
              discountedPrice: 299,
              description: 'Power-up your business',
              items: [
                { name: 'Access to DO Engine', included: true },
                { name: 'Onboarding', included: true },
                { name: 'Automated Campaign Management', included: true },
                { name: 'Dedicated Consultant', included: false },
                { name: 'Sales Consulting', included: false },
                { name: 'Pricing Consulting', included: false },
                { name: 'Marketing Consulting', included: false },
                { name: 'Performance Reviews', included: false },


              ]
          },
      ],
      FR: [
          {
              title: 'BASIC',
              price: 99,
              discountedPrice: null,
              description: 'Free Trial For 7 days',
              items: [
                { name: 'Access to DO Engine', included: true },
                { name: 'Onboarding', included: false },
                { name: 'Automated Campaign Management', included: false },
                { name: 'Dedicated Consultant', included: false },
                { name: 'Sales Consulting', included: false },
                { name: 'Pricing Consulting', included: false },
                { name: 'Marketing Consulting', included: false },
                { name: 'Performance Reviews', included: false },

              ]
          },
          {
              title: 'PREMIUM',
              price: 499,
              discountedPrice: 299,
              description: 'Power-up your business',
              items: [
                { name: 'Access to DO Engine', included: true },
                { name: 'Onboarding', included: true },
                { name: 'Automated Campaign Management', included: true },
                { name: 'Dedicated Consultant', included: false },
                { name: 'Sales Consulting', included: false },
                { name: 'Pricing Consulting', included: false },
                { name: 'Marketing Consulting', included: false },
                { name: 'Performance Reviews', included: false },

              ]
          },
      ],
      GB: [
          {
              title: 'BASIC',
              price: 99,
              discountedPrice: null,
              description: 'Free Trial For 7 days',
              items: [
                { name: 'Access to DO Engine', included: true },
                { name: 'Onboarding', included: false },
                { name: 'Automated Campaign Management', included: false },
                { name: 'Dedicated Consultant', included: false },
                { name: 'Sales Consulting', included: false },
                { name: 'Pricing Consulting', included: false },
                { name: 'Marketing Consulting', included: false },
                { name: 'Performance Reviews', included: false },

              ]
          },
          {
              title: 'PREMIUM',
              price: 499,
              discountedPrice: 299,
              description: 'Power-up your business',
              items: [
                { name: 'Access to DO Engine', included: true },
                { name: 'Onboarding', included: true },
                { name: 'Automated Campaign Management', included: true },
                { name: 'Dedicated Consultant', included: false },
                { name: 'Sales Consulting', included: false },
                { name: 'Pricing Consulting', included: false },
                { name: 'Marketing Consulting', included: false },
                { name: 'Performance Reviews', included: false },
              ]
          },
      ],
      AE: [
          {
              title: 'BASIC',
              price: 399,  
              discountedPrice: null,
              description: 'Free Trial For 7 days',
              items: [
                { name: 'Access to DO Engine', included: true },
                { name: 'Onboarding', included: false },
                { name: 'Automated Campaign Management', included: false },
                { name: 'Dedicated Consultant', included: false },
                { name: 'Sales Consulting', included: false },
                { name: 'Pricing Consulting', included: false },
                { name: 'Marketing Consulting', included: false },
                { name: 'Performance Reviews', included: false },

              ]
          },
          {
              title: 'PREMIUM',
              price: 1799,
              discountedPrice: 999,
              description: 'Power-up your business',
              items: [
                { name: 'Access to DO Engine', included: true },
                { name: 'Onboarding', included: true },
                { name: 'Automated Campaign Management', included: true },
                { name: 'Dedicated Consultant', included: false },
                { name: 'Sales Consulting', included: false },
                { name: 'Pricing Consulting', included: false },
                { name: 'Marketing Consulting', included: false },
                { name: 'Performance Reviews', included: false },

              ]
          },
      ],
      SA: [
          {
              title: 'BASIC',
              price: 399,
              discountedPrice: null,
              description: 'Free Trial For 7 days',
              items: [
                { name: 'Access to DO Engine', included: true },
                { name: 'Onboarding', included: false },
                { name: 'Automated Campaign Management', included: false },
                { name: 'Dedicated Consultant', included: false },
                { name: 'Sales Consulting', included: false },
                { name: 'Pricing Consulting', included: false },
                { name: 'Marketing Consulting', included: false },
                { name: 'Performance Reviews', included: false },

              ]
          },
          {
              title: 'PREMIUM',
              price: 1799,
              discountedPrice: 999,
              description: 'Power-up your business',
              items: [
                { name: 'Access to DO Engine', included: true },
                { name: 'Onboarding', included: true },
                { name: 'Automated Campaign Management', included: true },
                { name: 'Dedicated Consultant', included: false },
                { name: 'Sales Consulting', included: false },
                { name: 'Pricing Consulting', included: false },
                { name: 'Marketing Consulting', included: false },
                { name: 'Performance Reviews', included: false },

              ]
          },
      ],
      };

      const defaultPlans = plans.US;
      const countryPlans = plans[state.country] || defaultPlans;
      commit('setCountryPlans', countryPlans);

      const currencySymbol = currencySymbolsMap[state.country] || defaultCurrencySymbol;
      commit('setCurrencySymbol', currencySymbol);
    },
  },
  modules: {
  }
})