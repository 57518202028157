<template>
    <div :id="id">
    <div class="journey">
        <div class="journey-content">
            <div class="journey-text-section">
                <h2>Let’s Grow Together</h2>
                <p>Get access to our DO Engine & Services</p>
            </div>
        </div>
    </div>
    <div class="wrapper">
        <div class="card services-card">
      <div class="top">
        <div class="title">Services</div>
      </div>
      <div class="desc">Services just for you</div>
      <div class="features">
        <div class="item" v-for="item in serviceItems" :key="item">
          <span>{{ item }}</span>
        </div>
      </div>
      <button class="btn-2"></button>
    </div>
    <div class="plans-wrapper">
      <div class="card" v-for="plan in countryPlans" :key="plan.title">
        <div class="top">
                <div class="title">{{ plan.title }}</div>
                <div class="price">
                    <span>{{ currencySymbol }}
                        <span v-if="plan.discountedPrice">
                        <s>{{ plan.price }}</s>
                        <span class="discounted">{{ plan.discountedPrice }}</span>
                    </span>
                    <span v-else>{{ plan.price }}</span>
                    <small>/month</small>
                    </span>      
                    
                </div>
            </div>

            <div class="desc">{{ plan.description }}</div>
        <div class="features">
          <div class="item" v-for="item in plan.items" :key="item.name">
            <span class="icon" :class="item.included ? 'check' : 'cross'">
              {{ item.included ? '✓' : '✗' }}
            </span>
          </div>
          <button class="btn" @click="handleSubscription(plan.title, plan.price, plan.discountedPrice)">
            Add to Cart
          </button>
        </div>
      </div>
        <div class="card">
            <div class="top">
                <div class="title">ELITE</div>
                <div class="price"><span>Contact Us</span></div>
            </div>

            <div class="desc">Customized just for you</div>

            <div class="features">
                <div class="item">
                    <span class="check">✓</span>
                </div>
                <div class="item">
                    <span class="check">✓</span>
                </div>
                <div class="item">
                    <span class="check">✓</span>
                </div>
                <div class="item">
                    <span class="check">✓</span>
                </div>
                <div class="item">
                    <span class="check">✓</span>
                </div>
                <div class="item">
                    <span class="check">✓</span>
                </div>
                <div class="item">
                    <span class="check">✓</span>
                </div>
                <div class="item">
                    <span class="check">✓</span>
                </div>
                <button class="btn" @click="handleContact">Contact Us</button>
            </div>
        </div>
    </div>
    </div>
</div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {

    data() {
        return {
            isLoggedIn: localStorage.getItem("token") != null,
            isLoggedOut: localStorage.getItem("token") == null,
            serviceItems: ['Access to DO Engine', 'Onboarding', 'Automated Campaign Management', 'Dedicated Consultant', 'Sales Consulting', 'Pricing Consulting', 'Marketing Consulting', 'Performance Reviews'],

        };

    },
    computed: {
        ...mapState(['country', 'currencySymbol', 'countryPlans', 'currency'])
    },
    props: {
        id: {
      type: String,
      default: ''
    },
    },

    methods: {
        ...mapActions(['detectCountry', 'setCountryPlans']),
        handleSubscription(plan, price, discountedPrice) {
            if (this.isLoggedIn) {
                this.$router.push({
                    path: '/CheckoutPage',
                    query: {
                        plan,
                        price: discountedPrice || price,
                        country: this.country,
                        currency: this.currency
                    }
                });
            } else {
                this.$router.push('/LoginSignup');
            }
        },
        handleContact() {
            this.$router.push({ name: "EliteContact" });
        },
    },
    async created() {
        if (!this.country || !this.currency) {
            await this.detectCountry();
        }
        this.setCountryPlans();
    },

}
</script>

<style scoped>
/*Journey css */
.journey {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
}

.journey-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    width: 100%;
}

.journey-text-section {
    margin-bottom: 20px;
}

.journey-text-section h2 {
    color: #2F847C;
    font-size: 2rem;
    margin-bottom: 10px;
}

.journey-text-section p {
    font-size: 1.2rem;
    color: #17252A;
}

.journey-image-section img {
    max-width: 100%;
    height: auto;
}

/* pricing css */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2F847C;
}

.wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 1175px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}


.plans-wrapper {
  display: flex;
  justify-content: space-between;
  width: 75%;
  
}
.plans-wrapper .card {
  width: calc(33.33% - 10px);
}


.wrapper .card {
    background: #fff;
    width: calc(33% - 20px);
    text-align: center;
    padding: 15px 30px 30px 30px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}

.wrapper .card .top {
    height: 130px;
    background: #2F847C;
    color: #fff;
    padding: 12px 0 0 0;
    clip-path: polygon(0 0, 100% 0, 100% 53%, 50% 100%, 0 53%);
}

.wrapper .card .top .title {
    font-size: 27px;
    font-weight: 600;
}

.wrapper .card .top .price {
    font-size: 20px;
    font-weight: 600;
    margin-top: -10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper .card .top .price span {
    font-size: 16px;
}

.wrapper .card .top .price s {
    font-size: 18px;
    color: whitesmoke;
    position: relative;
}

.wrapper .card .top .price s::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -5%;
    right: -5%;
    height: 2px;
    background-color: #0e0f0f;
    transform: rotate(-10deg);
}

.wrapper .card .top .price .discounted {
    /* color: white; */
    font-size: 20px;
    text-shadow: 1px 1px 2px rgba(19, 18, 18, 0.3);
    animation: pulse 1.5s infinite;
    margin-left: 5px;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.25);
    }

    100% {
        transform: scale(1);
    }
}

.wrapper .card .desc {
    margin-top: 15px;
    font-size: 16px;
    color: cadetblue;
}

.wrapper .card .features .item {
    margin-top: 25px;
    font-size: 15px;
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.wrapper .card .features .item::before {
    position: absolute;
    content: "";
    width: 100%;
    background: #ddd;
    height: 1px;
    left: 0;
    top: -12px;
    border-radius: 25px;
}

.check {
    color: #2F847C;
    font-weight: bold;
}

.cross {
    color: #cd3241;
    font-weight: bold;

}

.wrapper .card .btn {
    display: block;
    height: 42px;
    width: 100%;
    background: #2F847C;
    color: #fff;
    outline: none;
    border: none;
    margin-top: 30px;
    border-radius: 3px;
    font-size: 18px;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 0.3s;
}
.wrapper .card .btn-2 {
    display: block;
    height: 42px;
    width: 100%;
    background: #f9fafa;
    color: #fff;
    outline: none;
    border: none;
    margin-top: 30px;
    border-radius: 3px;
    font-size: 18px;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 0.3s;
}

.wrapper .card .btn:hover {
    filter: brightness(90%);
}

@media (max-width: 1000px) {
    .wrapper .card {
        width: calc(50% - 20px);
        margin-bottom: 30px;
    }
}

@media (max-width: 715px) {
    .wrapper .card {
        width: 100%;
    }
}
</style>