<template>
  <div>
    <NavbarPrimary :topLinks="topNavbarLinks" :bottomLinks="bottomNavbarLinks" :showProfileIcon="true" @scrollTo="scrollTo" />
  </div>
  <!-- <div class="welcome">
    <div class="welcome-text-section">
      <h2>Welcome</h2>
      <p>
        At Broadwing Labs, we help businesses
        expand globally and optimize their retail
        strategies. Our consultant shares insights
        on all aspects of retail growth which
        includes pricing, marketing, operations,
        competition insights
      </p>
    </div>
    <div class="welcome-image-section">
      <img src="E-CommerceWelcome.png" alt="Welcome" />
    </div>
  </div> -->
  
  <div id="approach" class="approach">
    <div class="approach-content">
      <div class="approach-text-section">
        <h2>Our Approch</h2>
        <p>From Confusion to Clarity: Your Market Journey</p>
      </div>
      <div class="approach-image-section">
        <img src="Approach.png" alt="approach image" width="1000px">
      </div>
    </div>
  </div>

  <!-- <div class="platform">
    <div class="platform-content">
      <div class="platform-text-section">
        <h2>OUR PLATFORMS</h2>
      </div>
      <div class="platform-image-section">
        <img src="Platform.png" alt="Platform image">
      </div>
    </div>
  </div> -->
<!-- contact us part -->
<ContactUs id="contact"
    title="Get in Touch"
    :showImage="true"
    :fields="[
      { name: 'name', type: 'text', placeholder: 'Your Name', required: true },
      { name: 'contactNumber', type: 'tel', placeholder: 'Contact Number', required: false },
      { name: 'email', type: 'email', placeholder: 'Your Email', required: true },
      { name: 'CompanyProduct', type: 'text', placeholder: 'Company/Product', required: false },
      { name: 'AnnualTurnover', type: 'text', placeholder: 'AnnualTurnover', required: false },
      { name: 'subject', type: 'text', placeholder: 'Subject', required: true },
      { name: 'message', type: 'textarea', placeholder: 'Your Message', required: true }
    ]"
    submitButtonText="Send"
  />



  <footerComp />

</template>

<script>
import NavbarPrimary from '@/components/NavbarPrimary.vue';
import ContactUs from '../components/ContactUs.vue';
import footerComp from '../components/footerComp.vue';
export default {
  components: {
    NavbarPrimary,
    footerComp,
    ContactUs
  },
  data() {
    return {
      topNavbarLinks: [
        { to: "/", text: 'HOME' },
        { to: '/ConsultingCommerce', text: 'CONSULTING' },
        { to: '/PerformanceMarketing', text: 'MARKETING' },
        { to: '/career', text: 'CAREER' },
        

      ],
      bottomNavbarLinks: [
        
        
        { handler: 'scrollTo', target: 'approach', text: 'APPROACH' },
        { handler: 'scrollTo', target: 'contact', text: 'CONTACT' },

        
       
        
      ],
    };
  },
  methods: {
  scrollTo(target) {
    const element = document.getElementById(target);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
},
 
  
}

</script>

<style scoped>
/*welcome css */
/* .welcome {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.welcome-text-section {
  max-width: 50%;
  padding: 20px;
}

.welcome-text-section h2 {
  color: #2F847C;
  font-size: 2rem;
  margin-bottom: 20px;
}

.welcome-text-section p {
  font-size: 1.2rem;
  color: #17252A;
}

.welcome-image-section {
  max-width: 30%;
  padding: 20px;
  margin-top: 60px;
}

.welcome-image-section img {
  width: 100%;
  height: auto;
} */

/* approach css */
.approach {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
  text-align: center;
}

.approach-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  width: 100%;
}

/* .approach-text-section {
    margin-bottom: 20px;
} */

.approach-text-section h2 {
  color: #2F847C;
  font-size: 2rem;
  margin-bottom: 10px;
}

.approach-text-section p {
  font-size: 1.2rem;
  color: #17252A;
}

.approach-image-section img {
  max-width: 100%;
  height: 80vh;
}


/* platform css */
.platform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 20px; */
  text-align: center;
}

.platform-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  width: 100%;
}

.platform-text-section h2 {
  color: #2F847C;
  font-size: 2rem;
  /* margin-bottom: 10px; */
}

.platform-image-section img {
  max-width: 100%;
  height: 80vh;
}

/* journey css */
.journey {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
}

.journey-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  width: 100%;
}

.journey-text-section {
  margin-bottom: 20px;
}

.journey-text-section h2 {
  color: #2F847C;
  font-size: 2rem;
  margin-bottom: 10px;
}

.journey-text-section p {
  font-size: 1.2rem;
  color: #17252A;
}

.journey-image-section img {
  max-width: 100%;
  height: auto;
}

</style>
