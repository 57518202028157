// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#009688',
          accent: '#009688',
          secondary: '#ff5722',
          info: '#00bcd4',
          warning: '#ffc107',
          error: '#f44336',
          success: '#4caf50',
        },
      },
      theme: { dark: true }
    },
  },
})