<template>
  <div class="home-primary">
    <div class="wave-container">
      <NavbarMarketing :topLinks="topNavbarLinks" :bottomLinks="bottomNavbarLinks" :showProfileIcon="false" />
    </div>
    </div>
<!-- hero -->
<div class="container">
    <!-- Left column: text and button -->
    <div class="hero-content">
      <h1><span class="typewriter"></span></h1>
      <h2><br>Consulting and performance marketing</h2>
      <p>Our focus is to simplify e-commerce advertising with precision and relentless optimization, ensuring every dollar of your budget works harder for your business.</p>
      <a @click.prevent="goToLoginSignup" class="btn">Start Today</a>
    </div>

    <!-- Right column: image -->
    <div class="hero-image">
      <img src="home_img.png" alt="Team working on a project">
    </div>
  </div>
</template>

<script>
import NavbarMarketing from '@/components/NavbarMarketing.vue';
import { jwtDecode } from 'jwt-decode';
export default {
  components: {
    NavbarMarketing,
  },
  data() {
    return {
      isLoggedIn: localStorage.getItem("token") != null,
      isLoggedOut: localStorage.getItem("token") == null,
      username: localStorage.getItem("username"),
      isAdmin: false,
     
      topNavbarLinks: [
        { to: '/', text: 'HOME' },
        { to: '/ConsultingCommerce', text: 'CONSULTING' },
        { to: '/PerformanceMarketing', text: 'MARKETING' },
        { to: '/career', text: 'CAREER' },
      ],
      
      
    };

  },
  created() {
    this.checkAdmin();

  },
  computed: {
  bottomNavbarLinks() {
    return [
      { to: "/AmazonView", text: 'AMS OPTIMIZATION TOOL', show: this.isLoggedIn && (this.isAdmin || this.userPlan === 'PREMIUM') },
      { to: "/UploadView", text: 'MARKETING OPTIMIZATION TOOL', show: this.isLoggedIn && (this.isAdmin || this.userPlan === 'BASIC' || this.userPlan === 'PREMIUM') },
      { to: "/AdminView", text: 'ADMIN', show: this.isLoggedIn && this.isAdmin },
    ];
  },
},
  methods: {
    checkAdmin() {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          this.isAdmin = decodedToken.sub.is_admin;
        } catch (error) {
          console.error('Error decoding token:', error);
        }
      }
    },
    goToLoginSignup() {
      this.$router.push('/LoginSignup');
    },
  }

};
</script>

<style scoped>
/* .home-primary {
  position: relative;
}

.wave-container {
  position: relative;
  overflow: hidden;
} */

.home {
  /* text-align: center; */
  /* padding: 1px 5px; */
  /* position: absolute; */
  margin-top: 125px;
  /* bottom: 10rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%; */
}
.brand-logo {
  animation: scaleAnimation 2s infinite alternate;
}

.home p {
  font-size: 1.0em;
}
body {
      margin: 0;
      font-family: 'Arial', sans-serif;
      background: linear-gradient(135deg, #1a75e8, #17d17e);
      color: #fff;
      overflow-x: hidden;
    }
    a {
  cursor: pointer;
  text-decoration: none;
  color: white;
}
    .container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;
      display: flex;
      align-items: center;
      height: 100vh;
    }

    .hero-content {
      flex: 1;
    }

    .hero-content h1 {
      font-size: 3rem;
      font-weight: bold;
      margin: 0 0 20px 0;
      margin-bottom: 0;
      line-height: 1;
    }
    .hero-content h2 {
      font-size: 2rem;
      font-weight: bold;
      margin: 0 0 1px 0;
      margin-top: 0;
      margin-bottom: 5px;
      line-height: 1;
    }
    .hero-content p {
      font-size: 1.2rem;
      margin-bottom: 30px;
    }

    .hero-content .btn {
      padding: 15px 30px;
      background-color: #1a75e8;
      color: #fff;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: bold;
      border-radius: 5px;
      transition: background 0.3s;
    }

    .hero-content .btn:hover {
      background-color: #1057b6;
    }

    .hero-image {
      flex: 1;
      text-align: right;
    }
 
    .hero-image img {
      max-width: 60%;
      height: auto;
      border-radius: 10px;
    } 

    @media (max-width: 768px) {
      .container {
        flex-direction: column;
        text-align: center;
      }

      .hero-image {
        text-align: center;
        margin-top: 20px;
      }

      .hero-content h1 {
        font-size: 2.5rem;
      }

      .hero-content p {
        font-size: 1rem;
      }
    }
@keyframes typing {
  0%, 10% { content: ""; }
  11%, 15% { content: "B"; }
  16%, 20% { content: "Bu"; }
  21%, 25% { content: "Bus"; }
  26%, 30% { content: "Busi"; }
  31%, 35% { content: "Busin"; }
  36%, 40% { content: "Busine"; }
  41%, 45% { content: "Busines"; }
  46%, 50% { content: "Business"; }
  61%, 65% { content: "Business G"; }
  66%, 70% { content: "Business Gr"; }
  71%, 75% { content: "Business Gro"; }
  76%, 80% { content: "Business Grow"; }
  81%, 85% { content: "Business Growt"; }
  86%, 90% { content: "Business Growth"; }

  91%, 100% { content: ""; }
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.typewriter {
  --caret: currentcolor;
}

.typewriter::before {
  content: "";
  animation: typing 2s infinite; /* Adjust the duration as needed */
}

.typewriter::after {
  content: "";
  border-right: 2px solid var(--caret);
  animation: blink 0.5s linear infinite;
}

.typewriter.thick::after {
  border-right: 1ch solid var(--caret);
}

.typewriter.nocaret::after {
  border-right: 0;
}

@media (prefers-reduced-motion) {
  .typewriter::after {
    animation: none;
  }
}


</style>