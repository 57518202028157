<template>
  <div :id="id">
    <div class="journey">
      <div class="journey-content">
        <div class="journey-text-section">
          <h2>{{ title }}</h2>
        </div>
      </div>
    </div>
    <div class="arch_contact_us_duplicate">
      <div class="responsive-container-block big-container">
        <div class="responsive-container-block container">
          <div v-if="showImage" class="responsive-cell-block wk-mobile-12 wk-desk-5 wk-tab-10 wk-ipadp-5" id="ih6s">
            <img :src="imageSrc" :alt="imageAlt" class="text-blk section-subhead" width="500px" height="500px">
          </div>
          <div class="responsive-cell-block wk-ipadp-6 wk-mobile-12 wk-desk-5 wk-tab-9" id="i6df">
            <form @submit.prevent="sendEmail" class="form-wrapper">
              <template v-for="field in fields" :key="field.name">
                <input v-if="field.type !== 'textarea'" v-model="formData[field.name]" :type="field.type"
                  :name="field.name" :placeholder="field.placeholder" class="input input-element"
                  :required="field.required">
                <textarea v-else v-model="formData[field.name]" :name="field.name" :placeholder="field.placeholder"
                  class="textinput input-element" :required="field.required"></textarea>
              </template>
              <button type="submit" class="button">{{ submitButtonText }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
    {{ snackbar.message }}
    <v-btn color="white" text @click="snackbar.show = false">Close</v-btn>
  </v-snackbar>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: 'Contact Us'
    },
    showImage: {
      type: Boolean,
      default: true
    },
    imageSrc: {
      type: String,
      default: 'Contactinfographic.png'
    },
    imageAlt: {
      type: String,
      default: 'Contact Us'
    },
    id: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      default: () => [
        { name: 'name', type: 'text', placeholder: 'Name', required: true },
        { name: 'contactNumber', type: 'tel', placeholder: 'Contact Number', required: false },
        { name: 'email', type: 'email', placeholder: 'Email', required: true },
        { name: 'subject', type: 'text', placeholder: 'Subject', required: true },
        { name: 'message', type: 'textarea', placeholder: 'Message', required: true }
      ]
    },
    submitButtonText: {
      type: String,
      default: 'Send Message'
    }
  },
  data() {
    return {
      snackbar: {
        show: false,
        message: '',
        color: '',
        timeout: 4000
      },
      formData: {}
    };
  },
  created() {
    this.initializeFormData();
  },
  methods: {
    initializeFormData() {
      this.formData = this.fields.reduce((acc, field) => {
        acc[field.name] = '';
        return acc;
      }, {});
    },
    async sendEmail() {
      try {
        const response = await fetch('https://backend.broadwinglabs.com/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.formData)
        });

        if (!response.ok) {
          throw new Error(`HTTPS error! status: ${response.status}`);
        }

        // const data = await response.json();
        this.initializeFormData();
        this.showSnackbar('Email sent successfully!', 'success');
      } catch (error) {
        this.showSnackbar('Error sending email. Please try again later.', 'error');
      }
    },
    showSnackbar(message, type) {
      this.snackbar.message = message;
      this.snackbar.color = type === 'success' ? 'green' : 'red';
      this.snackbar.show = true;
    },
  },
  watch: {
    fields: {
      handler() {
        this.initializeFormData();
      },
      deep: true
    }
  }
}
</script>

<style scoped>
/* journey css */


.journey {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.journey-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  width: 100%;
}

.journey-text-section {
  margin-bottom: 20px;
}

.journey-text-section h2 {
  color: #2F847C;
  font-size: 2rem;
  margin-bottom: 10px;
}

.journey-text-section p {
  font-size: 1.2rem;
  color: #17252A;
}

.journey-image-section img {
  max-width: 100%;
  height: auto;
}

/* contact us css */

.arch_contact_us_duplicate * {
  font-family: Nunito, sans-serif;
}

.arch_contact_us_duplicate .icon-block svg {
  width: 100%;
  height: 100%;
}

.arch_contact_us_duplicate .icon-block {
  width: 30px;
  height: 30px;
}

.arch_contact_us_duplicate .text-blk {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  line-height: 25px;
}

.arch_contact_us_duplicate .responsive-container-block {
  min-height: 75px;
  height: fit-content;
  width: 100%;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  justify-content: flex-start;
}

.arch_contact_us_duplicate .responsive-cell-block {
  min-height: 75px;
}

.arch_contact_us_duplicate .responsive-container-block.big-container {
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-top: 0px;
  padding-right: 50px;
  padding-bottom: 0px;
  padding-left: 50px;
  background-color: #fdfce6;
  min-height: 10vh;
}

.arch_contact_us_duplicate .responsive-container-block.container {
  max-width: 1100px;
  justify-content: space-evenly;
}

.arch_contact_us_duplicate .text-blk.section-head {
  font-size: 60px;
  line-height: 65px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 24px;
  margin-left: 0px;
}

.arch_contact_us_duplicate .text-blk.section-subhead {
  font-size: 14px;
  line-height: 18px;
  color: #2F847C;
}

.arch_contact_us_duplicate .input {
  width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
  margin-left: 0px;
  height: 54px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-color: #767676;
  border-right-color: #767676;
  border-bottom-color: #767676;
  border-left-color: #767676;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  padding-top: 1px;
  padding-right: 16px;
  padding-bottom: 1px;
  padding-left: 16px;
  font-size: 16px;
}

.arch_contact_us_duplicate .textinput {
  width: 100%;
  height: 60px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 27px;
  margin-left: 0px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-color: #767676;
  border-right-color: #767676;
  border-bottom-color: #767676;
  border-left-color: #767676;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  font-size: 16px;
  padding-top: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
}

.arch_contact_us_duplicate .button {
  height: 50px;
  min-width: 240px;
  font-size: 16px;
  color: white;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-color: #767676;
  border-right-color: #767676;
  border-bottom-color: #767676;
  border-left-color: #767676;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 60px;
  margin-left: 0px;
  background-color: #2F847C;
}

.arch_contact_us_duplicate .form-wrapper {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: #d4dbdb;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  margin-left: 0px;
}

.arch_contact_us_duplicate .input-element {
  background-color: #e3e4e4;
}


@media (max-width: 1024px) {
  .arch_contact_us_duplicate .text-blk.section-head {
    font-size: 55px;
    line-height: 60px;
  }

  .arch_contact_us_duplicate .responsive-container-block.container {
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .arch_contact_us_duplicate .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-ipadp-6.wk-desk-5 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 80px;
    margin-left: 0px;
  }

  .arch_contact_us_duplicate .responsive-cell-block.wk-mobile-12.wk-ipadp-6.wk-desk-5.wk-tab-10 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 80px;
    margin-left: 0px;
    text-align: center;
  }

  .arch_contact_us_duplicate .input {
    height: 45px;
  }

  .arch_contact_us_duplicate .responsive-container-block.container {
    justify-content: space-evenly;
    margin-top: 80px;
    margin-right: 0px;
    margin-bottom: 80px;
    margin-left: 0px;
  }

  .arch_contact_us_duplicate .responsive-cell-block.wk-mobile-12.wk-desk-5.wk-tab-10.wk-ipadp-5 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 80px;
    margin-left: 0px;
  }
}

@media (max-width: 500px) {
  .arch_contact_us_duplicate .text-blk.section-head {
    font-size: 40px;
    line-height: 45px;
  }

  .arch_contact_us_duplicate .responsive-container-block.big-container {
    padding-top: 0px;
    padding-right: 20px;
    padding-bottom: 0px;
    padding-left: 20px;
    min-height: 10vh;
  }

  .arch_contact_us_duplicate .input {
    height: 40px;
  }

  .arch_contact_us_duplicate .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-ipadp-6.wk-desk-5 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 80px;
    margin-left: 0px;
  }

  .arch_contact_us_duplicate .responsive-cell-block.wk-mobile-12.wk-ipadp-6.wk-desk-5.wk-tab-10 {
    text-align: left;
    padding-top: 0px;
    padding-right: 20px;
    padding-bottom: 0px;
    padding-left: 20px;
  }

  .arch_contact_us_duplicate .responsive-container-block.big-container {
    padding-top: 0px;
    padding-right: 30px;
    padding-bottom: 0px;
    padding-left: 30px;
    min-height: 10vh;
  }

  .arch_contact_us_duplicate .responsive-cell-block.wk-mobile-12.wk-desk-5.wk-tab-10.wk-ipadp-5 {
    padding-top: 0px;
    padding-right: 20px;
    padding-bottom: 0px;
    padding-left: 20px;
  }

  .arch_contact_us_duplicate .button {
    width: 100%;
  }

  .arch_contact_us_duplicate .button {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 40px;
    margin-left: 0px;
    height: 50px;
  }

  .arch_contact_us_duplicate .form-wrapper {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 40px;
    margin-left: 0px;
  }

  .arch_contact_us_duplicate .icon-block {
    width: 25px;
    height: 25px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&amp;display=swap');

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
}

.wk-desk-1 {
  width: 8.333333%;
}

.wk-desk-2 {
  width: 16.666667%;
}

.wk-desk-3 {
  width: 25%;
}

.wk-desk-4 {
  width: 33.333333%;
}

.wk-desk-5 {
  width: 41.666667%;
}

.wk-desk-6 {
  width: 50%;
}

.wk-desk-7 {
  width: 58.333333%;
}

.wk-desk-8 {
  width: 66.666667%;
}

.wk-desk-9 {
  width: 75%;
}

.wk-desk-10 {
  width: 83.333333%;
}

.wk-desk-11 {
  width: 91.666667%;
}

.wk-desk-12 {
  width: 100%;
}

@media (max-width: 1024px) {
  .wk-ipadp-1 {
    width: 8.333333%;
  }

  .wk-ipadp-2 {
    width: 16.666667%;
  }

  .wk-ipadp-3 {
    width: 25%;
  }

  .wk-ipadp-4 {
    width: 33.333333%;
  }

  .wk-ipadp-5 {
    width: 41.666667%;
  }

  .wk-ipadp-6 {
    width: 50%;
  }

  .wk-ipadp-7 {
    width: 58.333333%;
  }

  .wk-ipadp-8 {
    width: 66.666667%;
  }

  .wk-ipadp-9 {
    width: 75%;
  }

  .wk-ipadp-10 {
    width: 83.333333%;
  }

  .wk-ipadp-11 {
    width: 91.666667%;
  }

  .wk-ipadp-12 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .wk-tab-1 {
    width: 8.333333%;
  }

  .wk-tab-2 {
    width: 16.666667%;
  }

  .wk-tab-3 {
    width: 25%;
  }

  .wk-tab-4 {
    width: 33.333333%;
  }

  .wk-tab-5 {
    width: 41.666667%;
  }

  .wk-tab-6 {
    width: 50%;
  }

  .wk-tab-7 {
    width: 58.333333%;
  }

  .wk-tab-8 {
    width: 66.666667%;
  }

  .wk-tab-9 {
    width: 75%;
  }

  .wk-tab-10 {
    width: 83.333333%;
  }

  .wk-tab-11 {
    width: 91.666667%;
  }

  .wk-tab-12 {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .wk-mobile-1 {
    width: 8.333333%;
  }

  .wk-mobile-2 {
    width: 16.666667%;
  }

  .wk-mobile-3 {
    width: 25%;
  }

  .wk-mobile-4 {
    width: 33.333333%;
  }

  .wk-mobile-5 {
    width: 41.666667%;
  }

  .wk-mobile-6 {
    width: 50%;
  }

  .wk-mobile-7 {
    width: 58.333333%;
  }

  .wk-mobile-8 {
    width: 66.666667%;
  }

  .wk-mobile-9 {
    width: 75%;
  }

  .wk-mobile-10 {
    width: 83.333333%;
  }

  .wk-mobile-11 {
    width: 91.666667%;
  }

  .wk-mobile-12 {
    width: 100%;
  }
}
</style>