<template>
    <v-container>
        <v-row align="center" justify="center" class="my-5">
            <v-col cols="12" md="6">
                <v-file-input v-model="file" color="deep-purple-accent-4" label="File input"
                    placeholder="Select your file" prepend-icon="mdi-paperclip" variant="outlined" class="file-input">
                </v-file-input>
            </v-col>
        </v-row>
        <v-row align="center" justify="center" class="my-5">
            <v-col cols="12" md="6" class="button-col">
                <v-btn @click="optimizeData" class="mx-2 my-2 optimize-btn" color="green darken-2"
                    elevation="10">Optimize</v-btn>
            </v-col>
        </v-row>
        <v-row align="center" justify="center">
            <v-col cols="12" md="4">
                <v-btn @click="downloadExcel" :disabled="!excelDownloadPath" color="info" block>Download Excel</v-btn>
            </v-col>
        </v-row>

        <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
            {{ snackbar.message }}
            <v-btn color="white" text @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            file: null,
            snackbar: {
                show: false,
                message: '',
                color: '',
                timeout: 4000
            },
            optimizationResult: null,
            excelDownloadPath: null,
            // profiles: [],
            // profileNames: [],
            // selectedProfile: null,
        }
    },
    methods: {
        showSnackbar(message, type) {
            this.snackbar.message = message;
            this.snackbar.color = type === 'success' ? 'green' : 'red';
            this.snackbar.show = true;
        },
        async optimizeData() {
            if (this.file) {
                try {
                    const formData = new FormData();
                    formData.append('file', this.file);

                    const uploadResponse = await fetch('https://backend.broadwinglabs.com/upload', {
                        method: 'POST',
                        body: formData,
                    });

                    if (!uploadResponse.ok) {
                        throw new Error(`File upload failed! status: ${uploadResponse.status}`);
                    }

                    const optimizeResponse = await fetch('https://backend.broadwinglabs.com/optimizeExcel', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    });

                    if (!optimizeResponse.ok) {
                        throw new Error(`https error! status: ${optimizeResponse.status}`);
                    }

                    const result = await optimizeResponse.json();

                    if (result.excel_path) {
                        this.excelDownloadPath = result.excel_path;
                        this.showSnackbar('Reports optimized successfully! Click "Download Excel" to get the file.', 'success');
                    } else {
                        this.showSnackbar('Reports optimized, but Excel file generation failed. Please try again.', 'warning');
                    }

                } catch (error) {
                    // console.error('Error in optimization process:', error);
                    this.showSnackbar('Failed to optimize reports. Please try again.', 'error');
                } finally {
                    this.optimizationLoading = false;
                }
            } else {
                this.showSnackbar("No file selected", 'error');
            }
        },
        async downloadExcel() {
            if (this.excelDownloadPath) {
                try {
                    const token = localStorage.getItem("token");
                    const response = await fetch(`https://backend.broadwinglabs.com/download_Excel_report?id=${encodeURIComponent(this.excelDownloadPath)}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    if (!response.ok) {
                        throw new Error(`https error! status: ${response.status}`);
                    }

                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = 'optimization_excel_report.xlsx';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                } catch (error) {
                    // console.error('Error downloading file:', error);
                    this.showSnackbar('Failed to download the Excel file. Please try again.', 'error');
                }
            } else {
                this.showSnackbar('Excel file not ready. Please optimize first.', 'error');
            }
        },
    },
};
</script>

<style scoped>
.v-container {
    position: relative;
    min-height: 100vh;
    background: linear-gradient(135deg, rgb(184, 206, 203), rgb(242, 208, 245));
    overflow: hidden;
    margin-top: 125px;
}

@keyframes moveBackground {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 50% 50%;
    }

    100% {
        background-position: 100% 100%;
    }
}

.v-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('https://www.transparenttextures.com/patterns/asfalt-light.png');
    opacity: 0.4;
    animation: moveBackground 15s linear infinite;
    z-index: 0;
}

.v-container>* {
    position: relative;
    z-index: 1;
}

.my-5 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.file-input {
    animation: fadeIn 2.0s ease-in-out;
}

.button-col {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 2.0s ease-in-out;
}

.upload-btn,
.optimize-btn {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.upload-btn:hover,
.optimize-btn:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.v-snackbar {
    animation: slideUp 0.5s ease-in-out;
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}
</style>
