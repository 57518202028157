<template>
    <div class="payment-summary">
        <h1>Payment Summary</h1>
        <div class="summary-details">
            <p><strong>Message:</strong> {{ message }}</p>
            <p><strong>Transaction ID:</strong> {{ transactionId }}</p>
            <p><strong>Razorpay Payment ID:</strong> {{ paymentId }}</p>
            <p><strong>Razorpay Order ID:</strong> {{ orderId }}</p>
            <p><strong>Amount:</strong> {{ currency }} {{ amount }}</p>
            <p><strong>Created At:</strong> {{ createdAt }}</p>
            <p><strong>Plan:</strong> {{ plan }}</p>
        </div>
        <button @click="goToHome">Go to Home</button>
        <!-- <button @click="goToHome">Go to Home</button> -->
    </div>
</template>

<script>
export default {
    props: {
        message: String,
        transactionId: String,
        paymentId: String,
        orderId: String,
        amount: String,
        currency: String,
        createdAt: String,
        plan: String,
    },
    created() {
        console.log('Received props:', this.$props);
        console.log('Route query:', this.$route.query);
    },
    methods: {
        goToHome() {
            this.$router.push('/');
        }
    }
}
</script>
<style scoped>
.payment-summary {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    color: #333;
}

.summary-details {
    margin-bottom: 20px;
}

.summary-details p {
    margin-bottom: 10px;
}

button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background-color: #45a049;
}
</style>