<template>
  <v-container fluid class="pa-6 main-container">
    <v-row align="center" justify="center" class="main-container-items">
      <h2>Generate Report</h2>
    </v-row>
    <v-row align="center" justify="center" class="main-container-items">
      <v-col cols="12" md="4">
        <v-select v-model="selectedProfile" :items="profiles" label="Select Profile" item-value="value"
          item-title="title" return-object required></v-select>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="main-container-items">
      <v-col cols="12" sm="6" md="4">
        <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ props, on }">
            <v-text-field v-model="formattedPrevStartDate" label="Previous Start Date" prepend-icon="mdi-calendar"
              readonly v-bind="props" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="startDate" @input="validateDateRange1"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ props, on }">
            <v-text-field v-model="formattedPrevEndDate" label="Previous End Date" prepend-icon="mdi-calendar" readonly
              v-bind="props" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="endDate" @input="validateDateRange1" :min="startDate"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row v-if="prevDateRangeError" align="center" justify="center">
      <v-col cols="12" md="8">
        <v-alert type="error" dense>{{ prevDateRangeError }}</v-alert>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="main-container-items">
      <v-col cols="12" sm="6" md="4">
        <v-menu v-model="menu3" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ props, on }">
            <v-text-field v-model="formattedCurrStartDate" label="Current Start Date" prepend-icon="mdi-calendar"
              readonly v-bind="props" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="startDate2" @input="validateDateRange2"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-menu v-model="menu4" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ props, on }">
            <v-text-field v-model="formattedCurrEndDate" label="Current End Date" prepend-icon="mdi-calendar" readonly
              v-bind="props" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="endDate2" @input="validateDateRange2" :min="startDate2"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row v-if="currDateRangeError" align="center" justify="center">
      <v-col cols="12" md="8">
        <v-alert type="error" dense>{{ currDateRangeError }}</v-alert>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="mt-4">
      <v-col cols="12" md="4">
        <v-btn color="primary" @click="requestReport" block>
          Get Report
        </v-btn>
      </v-col>
      <v-col cols="12" md="4">
        <v-btn @click="getReportStatus" :disabled="!reportIds.length" block>Status</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="loading || overallStatus || optimizationLoading" class="mt-4">
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <div v-if="loading || optimizationLoading" class="text-center">
              <div class="loader-hourglass">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p v-if="optimizationLoading">Optimizing...</p>
            </div>
            <div v-if="overallStatus" class="text-center">
              <h3 class="mb-3">Status</h3>
              <v-chip :color="getStatusColor(overallStatus)" text-color="white">
                {{ overallStatus }}
              </v-chip>
            </div>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" md="4">
        <v-btn @click="optimize" :disabled="!allReportsCompleted" color="success" block>Optimize</v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" md="4">
        <v-btn @click="downloadExcel" :disabled="!excelDownloadPath" color="info" block>Download Excel</v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="my-5 main-container-items">
      <v-col cols="12" md="6">
        <v-file-input v-model="file" color="deep-purple-accent-4" label="File input" placeholder="Select your file"
          prepend-icon="mdi-paperclip" variant="outlined" class="file-input">
        </v-file-input>
      </v-col>

    </v-row>
    <v-row align="center" justify="center" class="my-5">
      <v-col cols="12" md="4">
        <v-btn @click="uploadFileToAmazon" :disabled="!file" color="info" block>Upload</v-btn>
      </v-col>
    </v-row>
  </v-container>
  <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
    {{ snackbar.message }}
    <v-btn color="white" text @click="snackbar.show = false">Close</v-btn>
  </v-snackbar>
</template>
<script>
export default {
  data() {
    return {
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      startDate: new Date(),
      endDate: new Date(),
      startDate2: new Date(),
      endDate2: new Date(),
      campaigns: [],
      file: null,
      profiles: [],
      profileNames: [],
      selectedProfile: null,
      snackbar: {
        show: false,
        message: '',
        color: '',
        timeout: 4000
      },
      reportIds: [],
      reportStatuses: [],
      overallStatus: '',
      loading: false,
      prevDateRangeError: '',
      currDateRangeError: '',
      reportUrls: [],
      reportData: {
        AdProductOptimizationDetail: null,
        sdAdProductOptimizationDetail: null,
        TargetingOptimisationDetail: null,
        sbTargetingOptimisationDetail: null,
        sdTargetingOptimisationDetail: null,
        SearchTermOptimisationDetail: null,
        sbSearchTermOptimisationDetail: null,
        PreviousTargetingOptimisationDetail: null,
        sbPreviousTargetingOptimisationDetail: null,
        sdPreviousTargetingOptimisationDetail: null,
      },
      optimizationLoading: false,
      optimizationResult: null,
      excelDownloadPath: null,
    };

  },
  computed: {
    formattedPrevStartDate() {
      return this.formatDate(this.startDate);
    },
    formattedPrevEndDate() {
      return this.formatDate(this.endDate);
    },
    formattedCurrStartDate() {
      return this.formatDate(this.startDate2);
    },
    formattedCurrEndDate() {
      return this.formatDate(this.endDate2);
    },
    allReportsCompleted() {
      return this.reportStatuses.length > 0 &&
        this.reportStatuses.every(status => status.status === 'COMPLETED');
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
      return d.toISOString().split('T')[0];
    },
    validateDateRange1() {
      this.prevDateRangeError = '';
      if (this.startDate && this.endDate) {
        const start = new Date(this.startDate);
        const end = new Date(this.endDate);

        if (start > end) {
          this.prevDateRangeError = 'Start date must be before or equal to end date';
          this.endDate = null;
          return;
        }

        const timeDiff = Math.abs(end - start);
        const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;

        if (dayDiff > 31) {
          this.prevDateRangeError = 'Date range should not exceed 31 days';
          this.endDate = null;
        }
      }
    },

    validateDateRange2() {
      this.currDateRangeError = '';
      if (this.startDate2 && this.endDate2) {
        const start = new Date(this.startDate2);
        const end = new Date(this.endDate2);

        if (start > end) {
          this.currDateRangeError = 'Start date must be before or equal to end date';
          this.endDate2 = null;
          return;
        }

        const timeDiff = Math.abs(end - start);
        const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;

        if (dayDiff > 31) {
          this.currDateRangeError = 'Date range should not exceed 31 days';
          this.endDate2 = null;
        }
      }
    },
    async fetchProfiles() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {

          return;
        }

        const response = await fetch('https://backend.broadwinglabs.com/get_profiles', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();

        if (response.ok && Array.isArray(data)) {
          this.profiles = data.map(profile => ({
            value: profile.ProfileId,
            title: profile.Name || profile.ProfileId,
            region: profile.region
          }));
        } else {
         
          this.profiles = [];
        }
      } catch (error) {
   
        this.showSnackbar("Error fetching profiles", 'error');
        this.profiles = [];
      }
    },
    async requestReport() {
      if (this.prevDateRangeError || this.currDateRangeError) {
        this.showSnackbar('Please correct the date range errors before requesting the report.', 'error');
        return;
      }
      const profileId = this.selectedProfile ? this.selectedProfile.value : null;

      if (!profileId) {
        this.showSnackbar('Please select a profile before requesting reports.', 'error');
        return;
      }
      try {
        const token = localStorage.getItem("token");
        const requests = [
          {
            profile_id: profileId,
            region: this.selectedProfile.region,
            start_date: this.formatDate(this.startDate),
            end_date: this.formatDate(this.endDate),
            report_type: 'targeting',
            report_name: 'PreviousTargetingOptimisationDetail'
          },
          {
            profile_id: profileId,
            region: this.selectedProfile.region,
            start_date: this.formatDate(this.startDate),
            end_date: this.formatDate(this.endDate),
            report_type: 'sbtargeting',
            report_name: 'sbPreviousTargetingOptimisationDetail'
          },
          {
            profile_id: profileId,
            region: this.selectedProfile.region,
            start_date: this.formatDate(this.startDate),
            end_date: this.formatDate(this.endDate),
            report_type: 'sdtargeting',
            report_name: 'sdPreviousTargetingOptimisationDetail'
          },
          {
            profile_id: profileId,
            region: this.selectedProfile.region,
            start_date: this.formatDate(this.startDate2),
            end_date: this.formatDate(this.endDate2),
            report_type: 'targeting',
            report_name: 'TargetingOptimisationDetail'
          },
          {
            profile_id: profileId,
            region: this.selectedProfile.region,
            start_date: this.formatDate(this.startDate2),
            end_date: this.formatDate(this.endDate2),
            report_type: 'sbtargeting',
            report_name: 'sbTargetingOptimisationDetail'
          },
          {
            profile_id: profileId,
            region: this.selectedProfile.region,
            start_date: this.formatDate(this.startDate2),
            end_date: this.formatDate(this.endDate2),
            report_type: 'sdtargeting',
            report_name: 'sdTargetingOptimisationDetail'
          },
          {
            profile_id: profileId,
            region: this.selectedProfile.region,
            start_date: this.formatDate(this.startDate2),
            end_date: this.formatDate(this.endDate2),
            report_type: 'searchTerm',
            report_name: 'SearchTermOptimisationDetail'
          },
          {
            profile_id: profileId,
            region: this.selectedProfile.region,
            start_date: this.formatDate(this.startDate2),
            end_date: this.formatDate(this.endDate2),
            report_type: 'sbsearchTerm',
            report_name: 'sbSearchTermOptimisationDetail'
          },
          {
            profile_id: profileId,
            region: this.selectedProfile.region,
            start_date: this.formatDate(this.startDate2),
            end_date: this.formatDate(this.endDate2),
            report_type: 'spAdvertisedProduct',
            report_name: 'AdProductOptimizationDetail'
          },
          {
            profile_id: profileId,
            region: this.selectedProfile.region,
            start_date: this.formatDate(this.startDate2),
            end_date: this.formatDate(this.endDate2),
            report_type: 'sdAdvertisedProduct',
            report_name: 'sdAdProductOptimizationDetail'
          },
        ];

        const responses = await Promise.all(requests.map(req =>
          fetch('https://backend.broadwinglabs.com/request_report', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(req)
          }).then(res => res.json())
        ));

        this.reportIds = responses.map((res, index) => ({
          id: res.report_id,
          report_type: requests[index].report_type,
          report_name: requests[index].report_name
        }));
        this.showSnackbar('Reports Requested successfully! Please check status.', 'success');
      } catch (error) {
        this.showSnackbar('Report Request Unsuccessful! Please try Again later...', 'error');
      }
    },
    async getReportStatus() {
      this.loading = true;
      const profileId = this.selectedProfile ? this.selectedProfile.value : null;

      if (!profileId) {
        this.showSnackbar('Please select a profile before requesting reports.', 'error');
        return;
      }
      try {
        const token = localStorage.getItem("token");
        const statuses = await Promise.all(this.reportIds.map(async (report) => {
          try {
            const response = await fetch(`https://backend.broadwinglabs.com/report_status/${report.id}?profile_id=${profileId}&region=${this.selectedProfile.region}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });
            if (!response.ok) {
              throw new Error(`https error! status: ${response.status}`);
            }
            const status = await response.json();
            return {
              ...status,
              report_type: report.report_type,
              report_name: report.report_name
            };
          } catch (error) {
            return { status: 'ERROR', error: error.message, report_type: report.report_type, report_name: report.report_name };
          }
        }));

        this.reportStatuses = statuses;
        this.reportData = statuses.filter(status => status.url).map(status => ({
          url: status.url,
          name: status.name,
          report_type: status.report_type,
          report_name: status.report_name
        }));
        const allCompleted = this.reportStatuses.every(status => status.status === 'COMPLETED');
        const anyPending = this.reportStatuses.some(status => status.status === 'PENDING');
        const anyError = this.reportStatuses.some(status => status.status === 'ERROR');

        if (allCompleted) {
          this.overallStatus = 'COMPLETED';
          this.showSnackbar('All reports are ready for Optimization!', 'success');
        } else if (anyPending) {
          this.overallStatus = 'PENDING';
        } else if (anyError) {
          this.overallStatus = 'ERROR';
          this.showSnackbar('One or more report requests failed. Please try again.', 'error');
        }

      } catch (error) {
        this.showSnackbar('Failed to get report status. Please try again.', 'error');
      } finally {
        this.loading = false;
      }
    },

    async optimize() {
      this.optimizationLoading = true;
      try {
        const token = localStorage.getItem("token");
        const response = await fetch('https://backend.broadwinglabs.com/optimize_report', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            profile_id: this.selectedProfile.value,
            region: this.selectedProfile.region,
            report_data: this.reportData,
          }),
        });

        if (!response.ok) {
          throw new Error(`https error! status: ${response.status}`);
        }

        const result = await response.json();

        if (result.excel_path) {
          this.excelDownloadPath = result.excel_path;
          this.showSnackbar('Reports optimized successfully! Click "Download Excel" to get the file.', 'success');
        } else {
          this.showSnackbar('Reports optimized, but Excel file generation failed. Please try again.', 'warning');
        }

      } catch (error) {
        // console.error('Error in optimization process:', error);
        this.showSnackbar('Failed to optimize reports. Please try again.', 'error');
      } finally {
        this.optimizationLoading = false;
      }
    },
    async downloadExcel() {
      if (this.excelDownloadPath) {
        try {
          const token = localStorage.getItem("token");
          const response = await fetch(`https://backend.broadwinglabs.com/download_report?id=${encodeURIComponent(this.excelDownloadPath)}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            throw new Error(`https error! status: ${response.status}`);
          }

          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = 'optimization_report.xlsx';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } catch (error) {
          // console.error('Error downloading file:', error);
          this.showSnackbar('Failed to download the Excel file. Please try again.', 'error');
        }
      } else {
        this.showSnackbar('Excel file not ready. Please optimize first.', 'error');
      }
    },

    async uploadFileToAmazon() {
      if (this.file) {
        const formData = new FormData();
        formData.append('file', this.file);
        if (this.selectedProfile) {
          formData.append('profile_id', this.selectedProfile.value);
          formData.append('region', this.selectedProfile.region);
        } else {
          this.showSnackbar("No profile selected", 'error');
          return;
        }

        try {
          const response = await fetch('https://backend.broadwinglabs.com/uploadToAmazon', {
            method: 'POST',
            body: formData,
          });

          const data = await response.json();

          if (response.status === 200) {
            // const results = data.results;
            let message = "File uploaded and processed successfully.\n";
            
            this.showSnackbar(message, 'success');
          } else {
            this.showSnackbar(data.error || "Failed to upload and process file", 'error');
          }
        } catch (error) {
          this.showSnackbar("Error uploading and processing file", 'error');
        }
      } else {
        this.showSnackbar("No file selected", 'error');
      }
    },
    getStatusColor(status) {
      switch (status.toLowerCase()) {
        case 'completed':
          return 'success';
        case 'pending':
          return 'warning';
        case 'error':
          return 'error';
        default:
          return 'primary';
      }
    },

    showSnackbar(message, type) {
      this.snackbar.message = message;
      this.snackbar.color = type === 'success' ? 'green' : 'red';
      this.snackbar.show = true;
    },
  },
  mounted() {
    this.fetchProfiles();
  },
  watch: {
    startDate() {
      this.validateDateRange1();
    },
    endDate() {
      this.validateDateRange1();
    },
    startDate2() {
      this.validateDateRange2();
    },
    endDate2() {
      this.validateDateRange2();
    }
  }
};
</script>
<style scoped>
.pa-6 {
  padding: 24px;
}
.main-container{
  margin-top: 125px;
}

.loader-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loader-hourglass div {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fc0606;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader-hourglass div:nth-child(1) {
  right: 29px;
  left: 29px;
  animation: loader-hourglass1 1.2s infinite;
}

.loader-hourglass div:nth-child(2) {
  right: 18px;
  left: 18px;
  animation: loader-hourglass2 1.2s infinite;
}

.loader-hourglass div:nth-child(3) {
  right: 29px;
  left: 29px;
  animation: loader-hourglass1 1.2s infinite;
}

.loader-hourglass div:nth-child(4) {
  right: 40px;
  left: 40px;
  animation: loader-hourglass2 1.2s infinite;
}

@keyframes loader-hourglass1 {

  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

@keyframes loader-hourglass2 {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0);
  }
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;
}

.loader div {
  position: absolute;
  top: 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #f12424;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader div:nth-child(1) {
  left: 8px;
  animation: loader1 0.6s infinite;
}

.loader div:nth-child(2) {
  left: 8px;
  animation: loader2 0.6s infinite;
}

.loader div:nth-child(3) {
  left: 32px;
  animation: loader2 0.6s infinite;
}

.loader div:nth-child(4) {
  left: 56px;
  animation: loader3 0.6s infinite;
}

.main-container {
  background-color: white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); 
  border-radius: 8px; 
  border: 2px solid rgba(0, 0, 0, 0.05);
  background: linear-gradient(to bottom, rgb(234, 241, 239), #dff5f3);
}

.main-container-items{
  color: #17252A;
}

.v-btn {
  text-transform: none;
}

@keyframes loader1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes loader3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes loader2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}
</style>
