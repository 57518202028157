<template>
    <v-container class="campaign-container">

        <v-row align="center" justify="center" class="campaign-container-items">
            <v-col cols="12" sm="8" md="6">
                <h2>Enter Campaign Details</h2>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-select v-model="selectedProfile" :items="filteredProfiles" label="Select Profile"
                        item-value="value" item-title="title" @change="handleProfileChange" return-object required
                        hide-details>
                        <template v-slot:prepend-item>
                            <v-list-item>
                                <v-text-field v-model="profileSearch" label="Search profiles" @input="filterProfiles"
                                    hide-details dense outlined class="ma-2"></v-text-field>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                    </v-select>

                    <v-select v-model="selectedCampaign" :items="filteredCampaigns" label="Select Campaign"
                        item-value="campaignId" item-title="name" :disabled="!selectedProfile" return-object
                        @change="handleCampaignChange" required hide-details>
                        <template v-slot:prepend-item>
                            <v-list-item>
                                <v-text-field v-model="campaignSearch" label="Search campaigns" @input="filterCampaigns"
                                    hide-details dense outlined class="ma-2"></v-text-field>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                    </v-select>

                    <v-select v-model="selectedAdGroup" :items="filteredAdGroups" label="Select Ad Group"
                        item-value="adGroupId" item-title="name" :disabled="!selectedCampaign"
                        @change="handleAdGroupChange" return-object required hide-details>
                        <template v-slot:prepend-item>
                            <v-list-item>
                                <v-text-field v-model="adGroupSearch" label="Search ad groups" @input="filterAdGroups"
                                    hide-details dense outlined class="ma-2"></v-text-field>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                    </v-select>
                    <v-text-field v-model="ProjectedConversion" label="Projected Conversion (%)"
                        :rules="conversionRules" required></v-text-field>

                    <v-text-field v-model="ACOSTarget" label="ACOS Target (%)" :rules="conversionRules"
                        required></v-text-field>

                    <v-text-field v-model="ASP" label="ASP" :rules="aspRules" required></v-text-field>

                    <v-select v-model="selectedTargetingType" :items="TargetingTypes" item-title="text"
                        item-value="value" label="Select Keyword Type" required></v-select>

                    <v-select v-model="selectedAdType" :items="AdTypes" item-title="text" item-value="value"
                        label="Select Ad Type" required></v-select>
                    <v-btn :disabled="!valid" color="primary" @click="submitForm">Submit</v-btn>
                </v-form>
            </v-col>
        </v-row>

        <v-row class="mt-8 campaign-container-items" align="center" justify="center"  >
            <v-col cols="12">
                <v-select v-model="selectedProfile2" :items="filteredProfiles" label="Select Profile" item-value="value"
                    item-title="title" @change="handleProfileChange2" return-object required hide-details>
                    <template v-slot:prepend-item>
                        <v-list-item>
                            <v-text-field v-model="profileSearch" label="Search profiles" @input="filterProfiles"
                                hide-details dense outlined class="ma-2"></v-text-field>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>
                <v-card>
                    <h2>Campaign Details</h2>
                    <v-card-title>
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                            hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="campaigns" :search="search" :loading="loading"
                        class="elevation-1">
                        <template v-slot:item="{ item }">
                            <tr class="profile-row campaign-container-items" @click="highlightRow(item)">
                                <td>{{ profileIdToName[item.ProfileId] || item.ProfileId }}</td>
                                <td>{{ item.CampaignName }}</td>
                                <td>{{ item.CampaignId }}</td>
                                <td>{{ item.AdGroupName }}</td>
                                <td>{{ item.AdGroupId }}</td>
                                <td>{{ item.ProjectedConversion }}%</td>
                                <td>{{ item.ACOSTarget }}%</td>
                                <td>{{ item.ASP }}</td>
                                <td>{{ item.TargetingType }}</td>
                                <td>{{ item.AdType }}</td>
                                <td>
                                    <v-btn color="error" dark icon size="x-small" @click.stop="deleteItem(item.id)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
            {{ snackbar.message }}
            <v-btn color="white" text @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            valid: false,
            profiles: [],
            selectedProfile: null,
            selectedProfile2: null,
            selectedCampaign: null,
            selectedAdGroup: null,
            profileIdToName: {},
            filteredProfiles: [],
            filteredCampaigns: [],
            filteredAdGroups: [],
            profileSearch: '',
            campaignSearch: '',
            adGroupSearch: '',
            adGroups: [],
            campaigns: [],
            ProjectedConversion: '',
            ACOSTarget: '',
            ASP: '',
            TargetingType: [],
            AdType: [],
            selectedTargetingType: null,
            selectedAdType: null,
            TargetingTypes: [
                { text: 'Manual Keyword', value: 'MANUAL-KEYWORD' },
                { text: 'Manual Product', value: 'MANUAL-PRODUCT' },
                { text: 'Auto', value: 'AUTO' },
            ],
            AdTypes: [
                { text: 'SP', value: 'SP' },
                { text: 'SB', value: 'SB' },
                { text: 'SD', value: 'SD' },
            ],

            requiredRules: [(v) => !!v || 'This field is required'],
            conversionRules: [
                (v) => !!v || 'This field is required',
                (v) => /^\d+(\.\d{1,2})?$/.test(v) || 'Must be a valid percentage',
            ],
            aspRules: [
                (v) => !!v || 'This field is required',
                (v) => /^\d+(\.\d{1,2})?$/.test(v) || 'Must be a valid number',
            ],
            search: '',
            headers: [
                { title: 'Profile Name', value: 'ProfileId' },
                { title: 'Campaign Name', value: 'CampaignName' },
                { title: 'Campaign Id', value: 'CampaignId' },
                { title: 'Ad Group Name', value: 'AdGroupName' },
                { title: 'Ad Group Id', value: 'AdGroupId' },
                { title: 'Projected Conversion', value: 'ProjectedConversion' },
                { title: 'ACOS Target', value: 'ACOSTarget' },
                { title: 'Average ASP', value: 'ASP' },
                { title: 'TargetingType', value: 'TargetingType' },
                { title: 'AdType', value: 'AdType' },

            ],
            loading: false,
            highlightedProfileId: null,
            snackbar: {
                show: false,
                message: '',
                color: '',
                timeout: 4000,
            },
        };
    },
    methods: {
        showSnackbar(message, type) {
            this.snackbar.message = message;
            this.snackbar.color = type === 'success' ? 'green' : 'red';
            this.snackbar.show = true;
        },
        onInput(event) {
            this.KeywordType = event.target.value.toUpperCase();
        },

        async fetchProfiles() {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    return;
                }

                const response = await fetch('https://backend.broadwinglabs.com/get_profiles', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();

                if (response.ok && Array.isArray(data)) {
                    this.profiles = data.map(profile => ({
                        value: profile.ProfileId,
                        title: profile.Name || profile.ProfileId,
                        region: profile.region
                    }));
                    this.filteredProfiles = [...this.profiles];
                    this.profileIdToName = data.reduce((map, profile) => {
                        map[profile.ProfileId] = profile.Name;
                        return map;
                    }, {});

                } else {
                    // console.error('Invalid data received:', data);
                    this.profiles = [];
                }
            } catch (error) {
                // console.error('Error fetching profiles:', error);
                this.showSnackbar("Error fetching profiles", 'error');
                this.profiles = [];
            }
        },
        handleProfileChange(value) {
            this.selectedProfile = value;
            this.fetchCampaigns();
        },
        handleProfileChange2(value) {
            this.selectedProfile2 = value;
            if (value) {
                this.fetchCampaignDetails();
            }
        },
        handleCampaignChange(value) {
            this.selectedCampaign = value;
            this.fetchAdGroups();
        },
        handleAdGroupChange(selectedAdGroupId) {
            const fullAdGroup = this.adGroups.find(group => group.adGroupId === selectedAdGroupId);
            if (fullAdGroup) {
                this.selectedAdGroup = fullAdGroup;
            } else {
                this.showSnackbar('Selected ad group not found in adGroups array', 'error');
            }
        },
        filterProfiles() {
            if (this.profileSearch) {
                this.filteredProfiles = this.profiles.filter(profile =>
                    profile.title.toLowerCase().includes(this.profileSearch.toLowerCase())
                );
            } else {
                this.filteredProfiles = [...this.profiles];
            }
        },
        filterCampaigns() {
            if (this.campaignSearch) {
                this.filteredCampaigns = this.campaigns.filter(campaign =>
                    campaign.name.toLowerCase().includes(this.campaignSearch.toLowerCase())
                );
            } else {
                this.filteredCampaigns = [...this.campaigns];
            }
        },
        filterAdGroups() {
            if (this.adGroupSearch) {
                this.filteredAdGroups = this.adGroups.filter(adGroup =>
                    adGroup.name.toLowerCase().includes(this.adGroupSearch.toLowerCase())
                );
            } else {
                this.filteredAdGroups = [...this.adGroups];
            }
        },
        async fetchCampaigns() {
            if (!this.selectedProfile) {
                return;
            }

            const { value: profileId, region } = this.selectedProfile;

            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    return;
                }

                const response = await fetch(
                    `https://backend.broadwinglabs.com/list_sp_campaigns?profile_id=${profileId}&region=${region}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );
                const data = await response.json();
                if (response.ok && Array.isArray(data)) {
                    this.campaigns = data.map(campaign => ({
                        campaignId: campaign.campaignId,
                        name: campaign.name
                    }));
                    this.filteredCampaigns = [...this.campaigns];
                } else {
                    this.campaigns = [];
                }
            } catch (error) {
                // console.error('Error fetching campaigns:', error);
                this.showSnackbar('Error fetching campaigns', 'error');
                this.campaigns = [];
            }
        },
        async fetchAdGroups() {
            if (!this.selectedProfile || !this.selectedCampaign) {
                return;
            }

            const { value: profileId, region } = this.selectedProfile;
            const campaignId = this.selectedCampaign.campaignId;

            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    return;
                }
                const response = await fetch(
                    `https://backend.broadwinglabs.com/list_sp_ad_groups?profile_id=${profileId}&region=${region}&campaign_id=${campaignId}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );
                const data = await response.json();

                if (response.ok && Array.isArray(data)) {
                    this.adGroups = data.map(group => ({
                        adGroupId: group.adGroupId,
                        name: group.name
                    }));
                    this.filteredAdGroups = [...this.adGroups];
                } else {
                    // console.error('Invalid data received from backend:', data);
                    this.adGroups = [];
                }
            } catch (error) {
                // console.error('Error fetching ad groups:', error);
                this.showSnackbar('Error fetching ad groups', 'error');
                this.adGroups = [];
            }
        },
        async fetchCampaignDetails() {
            if (!this.selectedProfile2) {
                return;
            }

            const { value: profileId } = this.selectedProfile2;
            this.loading = true;
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    return;
                }
                const response = await fetch(`https://backend.broadwinglabs.com/get_campaign_details/${profileId}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    this.campaigns = data;
                } else {
                    // console.error('Error fetching campaign details:', response.statusText);
                    this.showSnackbar(`Error fetching campaign details: ${response.statusText}`, 'error');
                }
            } catch (error) {
                // console.error('Error fetching campaign details:', error);
                this.showSnackbar("Error fetching campaign details", 'error');
            } finally {
                this.loading = false;
            }
        },
        async submitForm() {

            if (!this.selectedAdGroup || typeof this.selectedAdGroup !== 'object') {
                this.showSnackbar('Please select a valid Ad Group', 'error');
                return;
            }

            if (this.$refs.form.validate()) {
                const campaignData = {
                    ProfileId: this.selectedProfile.value,
                    CampaignName: this.selectedCampaign.name,
                    CampaignId: this.selectedCampaign.campaignId,
                    AdGroupName: this.selectedAdGroup.name,
                    AdGroupId: this.selectedAdGroup.adGroupId,
                    ProjectedConversion: this.ProjectedConversion,
                    ACOSTarget: this.ACOSTarget,
                    ASP: this.ASP,
                    TargetingType: this.selectedTargetingType,
                    AdType : this.selectedAdType
                };
                try {
                    const token = localStorage.getItem('token');
                    const response = await fetch('https://backend.broadwinglabs.com/amazon_campaign_details', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify(campaignData),
                    });

                    if (response.status === 201) {
                        this.$refs.form.reset();
                        this.$refs.form.resetValidation();
                        this.showSnackbar("Campaign Details Added Successfully", 'success');
                        this.fetchCampaignDetails();
                    } else {
                        const errorData = await response.json();
                        this.showSnackbar(errorData.message || "Unable to Add Campaign Details", 'error');
                    }
                } catch (error) {
                    // console.error('There was an error adding the Campaign Details:', error);
                    this.showSnackbar('Error Adding Campaign Details', 'error');
                }
            } else {
                this.showSnackbar('Please fill in the required fields correctly', 'error');
            }
        },
        async deleteItem(id) {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`https://backend.broadwinglabs.com/delete_campaign_detail/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    this.showSnackbar("Campaign detail deleted successfully", 'success');
                    this.fetchCampaignDetails();
                } else {
                    this.showSnackbar("Error deleting campaign detail", 'error');
                }
            } catch (error) {
                // console.error('Error deleting campaign detail:', error);
                this.showSnackbar("Error deleting campaign detail", 'error');
            }
        },
        highlightRow(item) {
            this.highlightedProfileId = item.ProfileId;
        },

    },
    mounted() {
        this.fetchProfiles();
    },
    watch: {
        selectedProfile(newValue) {
            if (newValue) {
                this.fetchCampaigns();
            }
        },
        selectedCampaign(newValue) {
            if (newValue) {
                this.fetchAdGroups();
            }
        },
        selectedProfile2(newValue) {
            if (newValue) {
                this.fetchCampaignDetails();
            }

        }
    },
};
</script>

<style scoped>
h2 {
    text-align: center;
    margin-bottom: 20px;
}

.profile-row {
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.profile-row:hover {
    background-color: #f5f5f5;
}

.highlighted {
    background-color: #fcbcbc;
}

.campaign-container {
  background-color: white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); 
  border-radius: 8px; 
  border: 2px solid rgba(0, 0, 0, 0.05);
  background: linear-gradient(to bottom, #dff5f3, rgb(234, 241, 239));
}

.campaign-container-items{
  color: #17252A;
}

.v-data-table {
    transition: opacity 0.3s ease;
}
</style>