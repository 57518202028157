<template>
    <v-app>
        <NavbarPrimary :topLinks="topNavbarLinks" :showProfileIcon="false" />
        <v-main>
            <v-container style="padding-top: 125px;">
                <v-row align="center" justify="center">
                    <v-col cols="12" sm="8" md="10">
                        <v-card class="elevation-12">
                            <v-window v-model="step" class="fade-transition">
                                <v-window-item :value="1">
                                    <v-row no-gutters>
                                        <v-col cols="12" md="8" class="border1">
                                            <v-card-text class="py-12 px-8">
                                                <h2 class="text-center display-2 teal--text text--accent-3">
                                                    Sign in with Google
                                                </h2>
                                                <div class="text-center mt-4">
                                                    <!--   <v-btn class="mx-2" fab color="blue darken-3" outlined
                                                        @click="gotohello">
                                                        <v-icon>Hello</v-icon> 
                                                        </v-btn>
                                                -->

                                                    <v-btn class="mx-2" fab color="#DB4437" outlined
                                                        @click="handleGoogleSignin">
                                                        <v-icon>mdi-google</v-icon>
                                                    </v-btn>
                                                    <!-- <v-btn class="mx-2" fab color="#FF9900" outlined
                                                        @click="loginWithProvider('amazon')">
                                                        <v-icon>mdi-amazon</v-icon>
                                                    </v-btn> -->
                                                </div>

                                                <h4 class="text-center mt-4">OR</h4>
                                                <h3 class="text-center display-2 teal--text text--accent-3">
                                                    Sign in with Email ID
                                                </h3>
                                                <v-form @submit.prevent="handleSignIn">

                                                    <v-text-field v-model="signIn.email" label="Email"
                                                        prepend-icon="mdi-email" type="email" color="teal accent-3"
                                                        outlined required></v-text-field>

                                                    <v-text-field v-model="signIn.password" :type="showPassword ? 'text' : 'password'" 
                                                        label="Password" id="password" prepend-icon="mdi-lock" color="teal accent-3"
                                                        outlined required>
                                                        <template v-slot:append-inner>
                                                            <v-icon @click="togglePasswordVisibility" class="mr-2">{{ showPassword ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                                                        </template>
                                                    </v-text-field>

                                                    <div class="text-center mb-10">
                                                        <v-btn type="submit" rounded color="teal accent-3" dark
                                                            elevation="5">SIGN IN</v-btn>
                                                    </div>

                                                    <!-- <p class="text-center mt-0">
                                                        <a href="#" class="teal--text text--accent-3">Forgot your
                                                            password?</a>
                                                    </p> -->
                                                </v-form>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" md="4" class="teal accent-3" padding="0px">
                                            <v-card-text class="white--text py-12 px-8">
                                                <h1 class="text-center display-1">Hello, Friend!</h1>
                                                <h5 class="text-center display-2">
                                                    Enter your personal details and start your journey with us
                                                </h5>
                                            </v-card-text>
                                            <div class="text-center my-3">
                                                <v-btn rounded outlined dark @click="step++" elevation="5">SIGN
                                                    UP</v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-window-item>
                                <v-window-item :value="2">
                                    <v-row no-gutters>
                                        <v-col cols="12" md="4" class="teal accent-3 border1">
                                            <v-card-text class="white--text py-12 px-8">
                                                <h1 class="text-center display-1">Welcome Back!</h1>
                                                <h5 class="text-center display-2">
                                                    To keep connected with us please login with your personal info
                                                </h5>
                                            </v-card-text>
                                            <div class="text-center my-3">
                                                <v-btn rounded outlined dark @click="step--" elevation="5">SIGN
                                                    IN</v-btn>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="8">
                                            <v-card-text class="py-12 px-8">
                                                <h1 class="text-center display-2 teal--text text--accent-3">
                                                    Create Account
                                                </h1>
                                                <div class="text-center mt-4">
                                                    <!-- <v-btn class="mx-2" fab color="blue darken-3" outlined>
                                                        <v-icon>mdi-facebook</v-icon>
                                                    </v-btn> -->
                                                    <v-btn class="mx-2" fab color="#DB4437" outlined
                                                        @click="handleGoogleSignin">
                                                        <v-icon>mdi-google</v-icon>
                                                    </v-btn>
                                                    <!-- <v-btn class="mx-2" fab color="#FF9900" outlined>
                                                        <v-icon>mdi-amazon</v-icon>
                                                    </v-btn> -->
                                                </div>
                                                <h4 class="text-center mt-4">Ensure your email for registration</h4>
                                                <v-form @submit.prevent="handleSignUp">

                                                    <v-text-field v-model="signUp.name" label="Name"
                                                        prepend-icon="mdi-account" type="text" color="teal accent-3"
                                                        outlined required></v-text-field>

                                                    <v-text-field v-model="signUp.email" label="Email"
                                                        prepend-icon="mdi-email" type="email" color="teal accent-3"
                                                        outlined required></v-text-field>

                                                        <v-text-field v-model="signUp.password" :type="showPassword ? 'text' : 'password'"
                                                        label="Password" id="password" prepend-icon="mdi-lock" color="teal accent-3"
                                                        outlined required>
                                                        <template v-slot:append-inner>
                                                            <v-icon @click="togglePasswordVisibility" class="mr-2">{{ showPassword ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                                                        </template>
                                                    </v-text-field>

                                                    <v-text-field v-model="signUp.confirmPassword" :type="showConfirmPassword ? 'text' : 'password'"
                                                        label="Confirm Password" id="confirmPassword" prepend-icon="mdi-lock-check"
                                                        color="teal accent-3" outlined required>
                                                        <template v-slot:append-inner>
                                                            <v-icon @click="toggleConfirmPasswordVisibility" class="mr-2">{{ showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                                                        </template>
                                                    </v-text-field>
                                                        
                                                    <div class="text-center my-3">
                                                        <v-btn type="submit" rounded color="teal accent-3" dark
                                                            elevation="5">SIGN UP</v-btn>
                                                    </div>

                                                </v-form>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-window-item>
                            </v-window>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
                {{ snackbar.message }}
                <v-btn color="white" text @click="snackbar.show = false">Close</v-btn>
            </v-snackbar>
        </v-main>
    </v-app>
</template>
<script>
import NavbarPrimary from '../components/NavbarPrimary.vue';
import { jwtDecode } from 'jwt-decode';
import { googleTokenLogin } from "vue3-google-login";

export default {
    components: {
        NavbarPrimary,
    },
    data() {
        return {
            step: 1,
            signIn: {
                email: '',
                password: '',
                username: '',
            },
            signUp: {
                name: '',
                email: '',
                password: ''
            },
            snackbar: {
                show: false,
                message: '',
                color: '',
                timeout: 4000
            },
            passwordError: false, 
            showPassword: false, 
            showConfirmPassword: false,
            topNavbarLinks: [

                { to: '/', text: 'HOME' },
                { to: '/ConsultingCommerce', text: 'CONSULTING' },
                { to: '/PerformanceMarketing', text: 'MARKETING' },
                { to: '/career', text: 'CAREER' },
            ],
        };
    },
    methods: {
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        toggleConfirmPasswordVisibility() {
            this.showConfirmPassword = !this.showConfirmPassword;
        },
        async handleSignIn() {
            try {
                const response = await fetch('https://backend.broadwinglabs.com/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        email: this.signIn.email,
                        password: this.signIn.password
                    })
                });
                const result = await response.json();

                if (!response.ok) {
                    throw new Error(result.message || 'Login failed');
                }
                var decoded = jwtDecode(result.access_token);
                localStorage.setItem("token", result.access_token);
                localStorage.setItem('username', decoded.sub.username);

                this.showSnackbar('Login successful', 'success');
                this.$router.push({ name: "home" })
            } catch (error) {
                this.showSnackbar('Login Unsuccessful - Invalid Credentials', 'error');
            }
        },
        async handleSignUp() {
            if (this.signUp.password !== this.signUp.confirmPassword) {
                this.passwordError = true;
                this.showSnackbar('Passwords do not match', 'error');
                return;
            } else {
                this.passwordError = false;
            }
            try {
                const response = await fetch('https://backend.broadwinglabs.com/signup', { 
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        username: this.signUp.name,
                        email: this.signUp.email,
                        password: this.signUp.password
                    })
                });

                const result = await response.json();

                if (!response.ok) {
                    throw new Error(result.message || 'Signup failed');
                }

                this.showSnackbar('Signup successful', 'success');
                this.$router.push({ name: "LoginSignup" });
                this.$router.go();
            } catch (error) {
                this.showSnackbar(error.message, 'error');
            }
        },
        async handleGoogleSignin() {
            try {
                const googleUser = await googleTokenLogin()

                if (!googleUser || !googleUser.access_token) {
                    throw new Error('Failed to get Google authentication token')
                }

                const response = await fetch('https://backend.broadwinglabs.com/google_signin', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${googleUser.access_token}`
                    }
                })
                const result = await response.json()
                if (response.ok) {
                    this.showSnackbar(result.message, 'success')
                    localStorage.setItem("token", result.access_token)
                    localStorage.setItem('username', result.username)
                    const decodedToken = jwtDecode(result.access_token);
                    const expirationTime = decodedToken.exp * 1000;

                    this.setupAutoLogout(expirationTime);
                    this.$router.push({ name: "home" })
                } else {
                    throw new Error(result.message || 'Google signup failed')
                }
            } catch (error) {
                this.showSnackbar(error.message, 'error')
            }
        },
        loginWithProvider(provider) {
            console.log(`Login with ${provider}`);
        },
        setupAutoLogout(expirationTime) {
            const currentTime = new Date().getTime();
            const timeUntilExpiration = expirationTime - currentTime;

            if (timeUntilExpiration > 0) {
                setTimeout(() => {
                    this.logout();
                }, timeUntilExpiration);
            } else {
                this.logout();
            }
        },
        logout() {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            this.$router.push({ name: 'home' });
            this.showSnackbar('Your session has expired. Please log in again.', 'info');
        },
        showSnackbar(message, type) {
            this.snackbar.message = message;
            this.snackbar.color = type === 'success' ? 'green' : 'red';
            this.snackbar.show = true;
        }
    }
};
</script>

<style scoped>
.card-container {
    padding-top: 100px;
}

a {
    text-decoration: none;
}

.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.5s;
}

.fade-transition-enter,
.fade-transition-leave-to {
    opacity: 0;
}

.border1 {
    border-right: 5px solid rgb(0, 204, 255);
}

.display-1 {
    color: black;
}

.display-2 {
    color: black;
}
</style>