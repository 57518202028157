// main.js or main.ts
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import vue3GoogleLogin from 'vue3-google-login';
import { startTokenExpirationTimer } from './tokenService'; 
import { jwtDecode } from 'jwt-decode';
const CLIENT_ID = '946351294486-3n937rng5d0704m5co1ovv3k4fh1s2r1.apps.googleusercontent.com';
loadFonts();

const app = createApp(App);

app.use(router);
app.use(store);
app.use(vuetify);
app.use(vue3GoogleLogin, {
  clientId: CLIENT_ID,
});
const logoutCallback = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  router.push({ name: 'home' });
  console.log('User logged out due to token expiration');
};
const token = localStorage.getItem('token');
if (token) {
  const decodedToken = jwtDecode(token);
  const expirationTime = decodedToken.exp * 1000; 
  startTokenExpirationTimer(logoutCallback, expirationTime);
}


app.mount('#app');
